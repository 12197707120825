@use 'variables' as v;

html {
    font-size: 62.5%;
    box-sizing: border-box;
    scroll-padding-top: 0rem;
}

*,
*:before,
*:after {
    box-sizing: inherit;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
    font-family: v.$fuente_principal;
    font-size: 1.6rem;

    &.fijar-body {
        overflow: hidden;
    }

    &.body-scroll {
        padding-top: 8.6rem;
    }

    // position: relative;
    // padding-bottom: 40rem;
    // min-height: 100vh;

    // display: flex;
    // flex-direction: column;
    // min-height: 100vh;

    display: grid;
    min-height: 100vh;
    grid-template-rows: auto 1fr auto;
}

p,
li,
ul {
    font-size: 1.8rem;
    color: v.$negro;
    line-height: 1.5;
    font-weight: 300;
}

a {
    text-decoration: none;
    position: relative;
    overflow: hidden;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

    &::after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: transparent;
    }

    &:focus::after,
    &:active::after {
        background-color: rgba(0, 0, 0, 0.1);
        outline-color: transparent;
        outline-style: none;
    }

    &::-moz-focus-inner {
        border: 0;
    }

    &:focus {
        outline: none;
        -webkit-focus-ring-color: transparent;
    }
}

strong {
    font-weight: v.$bold;
}

img {
    max-width: 100%;
    width: 100%;
    height: auto;
    display: block;
}

.contenedor {
    padding: 2rem;
    max-width: 88rem;
    margin: 0 auto;
    width: 100%;
}

.contenedor-index {
    padding: 2rem;
    max-width: 94rem;
    margin: 0 auto;
    width: 100%;
}

main,
.contenedor {
    // margin-bottom: 10rem;
    text-align: justify;
    // hyphens: auto;
    // -webkit-hyphens: auto;
    width: 100%;
}

select {
    background-color: transparent;
    height: auto;
    border-radius: .4rem;
    border: 1px solid v.$negro;
}