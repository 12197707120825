@use '../base/variables' as v;

footer {
    background-color: v.$morado;
    height: 25rem;
    display: flex;
    justify-content: center;
    align-items: center;

    // position: absolute;
    // bottom: 0;
    // width: 100%;
    // margin-top: auto;

    .contenido {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        gap: 8rem;

        .direcciones {
            max-width: 23rem;
            width: 100%;

            p {
                height: 2.8rem;
                color: v.$blanco;
                font-size: 2rem;
                font-weight: v.$black;
                margin: 0;
                hyphens: none;
                text-align: left;
                width: 100%;
                line-height: 2.8rem;
                width: 23rem;
            }
        }

        .politicas {
            display: flex;
            flex-direction: column;
            max-width: 19.5rem;
            width: 100%;

            a {
                text-decoration: underline;
                color: v.$blanco;
                font-size: 2rem;
                font-weight: v.$black;
                margin: 0;
                hyphens: none;
                text-align: left;
                width: 100%;
                line-height: 2.8rem;
            }
        }

        .derechos {
            max-width: 20.7rem;
            width: 100%;
            height: 6.1rem;

            p {
                height: 2.8rem;
                color: v.$blanco;
                font-size: 2rem;
                font-weight: v.$bold;
                margin: 0;
                hyphens: none;
                text-align: left;
                width: 20.7rem;
                line-height: 2.8rem;
            }
        }
    }
}

@media screen and (max-width: 830px) {
    footer {
        height: 40rem;

        .contenido {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: auto;
            gap: 4rem;
        }
    }
}

@media screen and (max-width: 530px) {
    footer {
        height: 40rem;

        .contenido {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;

            .direcciones {
                max-width: 25rem;
                width: 100%;

                p {
                    width: 100%;
                    text-align: center;
                }
            }

            .politicas {
                width: 100%;
                max-width: 25rem;

                a {
                    text-align: center;
                    width: 100%;
                }
            }

            .derechos {
                width: 100%;
                max-width: 25rem;

                p {
                    text-align: center;
                    width: 100%;
                }
            }
        }
    }
}

@media screen and (max-width: 480px) {
    footer {
        .contenido {
            .direcciones {
                p {
                    font-size: 1.6rem;
                }
            }

            .politicas {
                a {
                    font-size: 1.6rem;
                }
            }

            .derechos {
                p {
                    font-size: 1.6rem;
                }
            }
        }
    }
}