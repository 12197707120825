@use '../base/variables' as v;

.detallesPedido {
    margin-top: 10rem;

    .encabezado {
        max-width: 76rem;
        margin: 0 auto;
        margin-bottom: 10rem;

        .volver {
            a {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: .5rem;

                img {
                    width: 2.4rem;
                    height: 2.4rem;
                    aspect-ratio: 1/1;
                    object-fit: cover;
                    transform: rotate(180deg);
                }

                p {
                    margin: 0;
                    font-size: 1.6rem;
                    font-weight: v.$bold;
                    color: v.$negro;
                }
            }
        }
    }

    .detallesProductosPedido {
        max-width: 60rem;
        width: 100%;
        background-color: #eee;
        border-radius: 1rem;
        border: none;
        margin: 0 auto;
        padding: 5rem 7.5rem;

        h3 {
            font-family: v.$cabin;
            margin: 0;
            margin-bottom: 2.5rem;
            font-size: 2.4rem;
            font-weight: v.$bold;
            text-align: left;
            hyphens: none;
        }

        .productosPedido {
            display: flex;
            flex-direction: column;
            gap: 1.5rem;

            .total {
                display: flex;
                justify-content: space-between;
                height: 5rem;
                margin-top: 3rem;
                align-items: center;
                background-color: #D9D9D9;
                border-radius: 2rem;
                padding: 1.6rem 3rem;

                p {
                    margin: 0;
                    font-size: 1.6rem;
                    color: rgba(v.$negro, 50%);
                    font-family: v.$cabin;

                }
            }

            .pedidoProducto {
                height: 18rem;
                background-color: #D9D9D9;
                border-radius: 2rem;
                padding: 1.2rem 1.5rem;

                .cantidadPrecio {
                    display: flex;
                    margin-top: 1.5rem;
                    justify-content: flex-end;
                    align-items: center;
                    gap: .5rem;

                    p {
                        margin: 0;
                    }

                    .x {
                        width: 2rem;
                        height: 2rem;
                        // background-color: rgba(#1C1B1F, 25%);
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        color: v.$blanco;
                        font-size: 1.6rem;
                        font-weight: v.$bold;
                        border-radius: 50%;
                    }

                    .cantidad {
                        background-color: rgba(#378CE7, 80%);
                        width: 5rem;
                        height: 4rem;
                        border-radius: 2rem;
                        color: v.$blanco;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-size: 1.6rem;
                        font-weight: v.$bold;
                        font-family: v.$cabin;
                    }

                    .precio {
                        background-color: rgba(#378CE7, 80%);
                        width: 10rem;
                        height: 4rem;
                        border-radius: 2rem;
                        color: v.$blanco;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-size: 1.6rem;
                        font-weight: v.$bold;
                        font-family: v.$cabin;
                    }
                }

                .descProducto {
                    width: 42rem;
                    margin: 0 auto;
                    height: 10rem;
                    display: flex;
                    background-color: #eee;
                    gap: 1.5rem;
                    border-radius: 2rem;
                    padding: .5rem .6rem;
                    align-items: center;

                    img {
                        width: 9rem;
                        height: 9rem;
                        aspect-ratio: 1/1;
                        object-fit: cover;
                        border-radius: 2rem;
                    }

                    .vermas {
                        width: 3.3rem;
                        height: 100%;
                        display: flex;
                        align-items: flex-end;
                        margin-bottom: 2rem;

                        a {
                            width: 3.3rem;
                            height: 3.3rem;
                            // background-color: v.$morado;
                            border-radius: 50%;
                            background-image: url('/build/img/pending.svg');
                            background-position: center center;
                            background-repeat: no-repeat;
                            border: none;
                            display: inline-block;
                        }
                    }

                    .datosProducto {
                        margin: 0;
                        padding: 0;
                        height: auto;
                        width: auto;
                        background-color: transparent;
                        max-width: 24rem;

                        p {
                            margin: 0;
                            font-size: 1.6rem;
                            font-family: v.$cabin;
                            color: rgba(v.$negro, 50%);
                            text-align: left;
                            hyphens: none;
                            line-height: 1.7rem;

                            &.nombre {
                                font-size: 2.4rem;
                                font-weight: v.$black;
                                hyphens: none;
                                text-align: left;
                                font-family: v.$fuente_principal;
                                color: v.$negro;
                                line-height: 3.4rem;
                            }
                        }
                    }
                }
            }
        }

        .datos {
            max-width: 45rem;
            border-radius: 1rem;
            background-color: #D9D9D9;
            height: 30rem;
            padding: 4.4rem;
            margin-bottom: 4.5rem;

            h5 {
                font-size: 2rem;
                font-weight: v.$black;
                color: rgba(v.$negro, 50%);
                hyphens: none;
                margin: 0;
                margin-bottom: .5rem;

                &.segundoTitulo {
                    margin-top: 2.5rem;
                }
            }

            .datosEnvio {
                p {
                    margin: 0;
                    font-size: 1.6rem;
                    line-height: 2.3rem;

                    span {
                        font-weight: 400;
                    }
                }
            }

            .direccionFecha {
                display: flex;
                gap: 5rem;

                .direccion {

                    .titulo {
                        font-size: 1.6rem;
                        text-align: left;
                        hyphens: none;
                        font-weight: 400;
                    }

                    p {
                        margin: 0;
                        text-align: left;
                        font-size: 1.6rem;
                        line-height: 2.3rem;
                        width: 100%;
                    }
                }

                .fecha {
                    p {
                        margin: 0;
                        font-size: 1.6rem;
                        text-align: left;
                        hyphens: none;
                        display: flex;
                        gap: .5rem;

                        span {
                            font-weight: 400;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 660px) {
    .detallesPedido {
        .detallesProductosPedido {
            padding: 2rem;
        }
    }
}

@media screen and (max-width: 530px) {
    .detallesPedido {
        .detallesProductosPedido {
            height: auto;
            max-width: 46rem;

            .datos {
                height: auto;

                .direccionFecha {
                    display: flex;
                    flex-direction: column;
                    gap: 2rem;
                }
            }

            .productosPedido {
                .pedidoProducto {
                    .descProducto {
                        height: auto;
                        width: 100%;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 450px) {
    .detallesPedido {
        .detallesProductosPedido {
            .productosPedido {
                .pedidoProducto {
                    height: auto;

                    .descProducto {
                        height: auto;
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;

                        img {
                            width: 100%;
                            height: 20rem;
                        }

                        .vermas {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
}