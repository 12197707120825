@use '../base/variables' as v;

.perfil {
    margin-top: 10rem;

    .masAcciones {
        background-color: #eee;
        max-width: 35rem;
        min-height: 30rem;
        border-radius: 1rem;
        padding: 4.2rem 4.8rem;
        margin-top: 6.5rem;

        .masInfo {
            margin-bottom: 1.5rem;

            h3 {
                margin: 0;
                font-size: 2.4rem;
                font-weight: v.$bold;
                text-align: left;
                hyphens: none;
            }

            p {
                margin: 0;
                font-size: 1.6rem;
                text-align: left;
                hyphens: none;
                color: rgba(v.$negro, 50%);
                font-family: v.$cabin;
                line-height: 1.9rem;
            }
        }

        hr {
            height: .1rem;
            background-color: #D9D9D9;
            border: none;
            width: 100%;
            margin: 0;
            margin-bottom: 1rem;
        }

        .eliminarCuenta {

            button {
                background-color: v.$rojoClaro;
                width: 10rem;
                height: 4rem;
                color: v.$blanco;
                border: none;
                border-radius: 2rem;
                font-size: 1.6rem;
                text-align: left;
                hyphens: none;
                font-family: v.$cabin;
                text-align: center;

                &:hover {
                    background-color: darken(v.$rojoClaro, 6);
                    cursor: pointer;
                }
            }

            div {

                &[popover] {
                    border: none;
                    padding: 2rem;
                    border-radius: .4rem;
                    box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.2);
                    background-color: lighten(v.$negro, 96);
                    max-width: 30rem;
                    width: 100%;

                }

                &::backdrop {
                    backdrop-filter: blur(3px);
                }

                h2 {
                    margin: 0;
                    margin-bottom: 2rem;
                    hyphens: none;
                    font-size: 1.6rem;
                    text-align: center;
                }

                .botones {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 1.5rem;

                    form {

                        button {
                            background-color: v.$morado;

                            &:hover {
                                background-color: darken(v.$morado, 6);
                                cursor: pointer;
                            }
                        }
                    }
                }

            }
        }

        .cerrar-sesion {
            display: flex;
            justify-content: flex-end;
            margin-bottom: 2rem;

            a {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: row-reverse;
                gap: 1rem;

                img {
                    width: 4rem;
                    height: 4rem;
                    aspect-ratio: 1/1;
                    object-fit: cover;
                }

                p {
                    margin: 0;
                    font-size: 1.6rem;
                    font-weight: v.$bold;
                    color: v.$negro;
                }
            }
        }
    }

    .encabezado {
        height: 4rem;
        max-width: 76rem;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0 auto;
        margin-bottom: 10rem;

        .volver {
            a {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: .5rem;

                img {
                    width: 2.4rem;
                    height: 2.4rem;
                    aspect-ratio: 1/1;
                    object-fit: cover;
                    // transform: rotate(180deg);
                }

                p {
                    margin: 0;
                    font-size: 1.6rem;
                    font-weight: v.$bold;
                    color: v.$negro;
                }
            }
        }
    }

    .contenido {
        max-width: 35rem;
        margin: 0 auto;

        h2 {
            margin: 0;
            text-align: left;
            hyphens: none;
            font-family: v.$cabin;
            font-size: 3.2rem;
            margin-bottom: 5.5rem;
        }

        .sinPedidos {
            font-size: 1.6rem;
            font-family: v.$cabin;
            font-weight: v.$bold;
            margin: 0;
            width: 100%;
            text-align: center;
            color: rgba(v.$negro, 50%);
        }

        .pedidos {

            h5 {
                margin: 0;
                font-size: 2.4rem;
                text-align: left;
                hyphens: none;
            }

            hr {
                border: none;
                background-color: #D9D9D9;
                height: .1rem;
                margin-bottom: 2rem;
            }

            .contenido {
                display: flex;
                flex-direction: column;
                gap: 1rem;
                max-height: 44rem; // Establece un máximo de altura
                overflow-y: auto;

                a {
                    min-height: 10rem;

                    .pedido {
                        display: flex;
                        // background-color: #D9D9D9;
                        border-radius: 2rem;
                        min-height: 10rem;
                        justify-content: center;
                        align-items: center;
                        gap: 3.5rem;

                        &.preparación {
                            background-color: #D9D9D9;
                        }

                        &.camino {
                            background-color: #EADBC8;
                        }

                        &.entregado {
                            background-color: #B7E5B4;
                        }

                        &.cancelado {
                            background-color: #F28585;
                        }

                        img {
                            width: 6rem;
                            height: 6.7rem;
                        }

                        .datos {
                            max-width: 18.5rem;
                            height: 6.7rem;

                            p {
                                margin: 0;
                                font-size: 1.6rem;
                                display: flex;
                                gap: .5rem;

                                &:first-child {
                                    font-weight: v.$bold;
                                }

                                span {
                                    font-weight: 500;
                                }
                            }
                        }
                    }
                }
            }
        }

        .datosPerfil {
            height: 40rem;
            background-color: #eee;
            border-radius: 1rem;
            margin-bottom: 5.5rem;

            padding: 4.1rem 2.5rem;

            .datosEstaticos {
                margin-bottom: 2.5rem;

                h5 {
                    margin: 0;
                    font-size: 2.4rem;
                    text-align: left;
                    hyphens: none;
                }

                hr {
                    border: none;
                    background-color: #D9D9D9;
                    height: .1rem;
                    margin-bottom: 1rem;
                }

                p {
                    display: flex;
                    gap: .5rem;
                    font-size: 1.6rem;
                    margin: 0;

                    span {
                        color: rgba(v.$negro, 50%);
                        font-weight: v.$bold;
                    }
                }
            }

            .datosDinamicos {

                h5 {
                    margin: 0;
                    font-size: 2.4rem;
                    text-align: left;
                    hyphens: none;
                }

                hr {
                    border: none;
                    background-color: #D9D9D9;
                    height: .1rem;
                    margin-bottom: 1rem;
                }

                form {
                    display: flex;
                    flex-direction: column;

                    .botones {
                        margin-top: 2.8rem;
                        display: flex;
                        gap: 1rem;

                        .cancelar {
                            background-color: v.$rojoClaro;

                            &:hover {
                                background-color: darken(v.$rojoClaro, 6);
                            }
                        }

                        button[type="submit"] {
                            background-color: v.$morado;

                            &:hover {
                                background-color: darken(v.$morado, 6);
                            }
                        }

                        button[type="submit"],
                        .cancelar {
                            border-radius: 2rem;
                            height: 4rem;
                            color: v.$blanco;
                            padding: 0;
                            border: none;
                            width: 10rem;
                            font-size: 1.6rem;
                            font-family: v.$cabin;
                            font-weight: v.$bold;
                            display: flex;
                            justify-content: center;
                            align-items: center;

                            &:hover {
                                cursor: pointer;
                            }
                        }
                    }

                    .campos {
                        display: flex;
                        gap: 1rem;

                        .campo {
                            width: 13rem;
                            display: flex;
                            flex-direction: column;
                            gap: .5rem;

                            label {
                                font-size: 1.6rem;
                                hyphens: none;
                                margin: 0;
                                padding: 0;
                                color: rgba(v.$negro, 50%);
                                font-weight: v.$bold;
                            }

                            input,
                            select {
                                border: none;
                                padding: 0;
                                text-align: center;
                                border-radius: 1rem;
                                background-color: #D9D9D9;
                                height: 4rem;
                                font-size: 1.6rem;

                                &:focus {
                                    outline: none;
                                }

                                // Para navegadores basados en WebKit (Chrome, Safari, Edge, Opera)
                                &::-webkit-inner-spin-button,
                                &::-webkit-outer-spin-button {
                                    -webkit-appearance: none;
                                    margin: 0;
                                }

                                // Para Firefox
                                &::-moz-focus-inner {
                                    border: 0;
                                }

                                // Para todos los navegadores
                                -moz-appearance: textfield;
                            }

                            select {
                                -webkit-appearance: none;
                                /* Para Chrome, Safari, Edge, Opera */
                                -moz-appearance: none;
                                /* Para Firefox */
                                appearance: none;
                                background-image: url('/build/img/flecha.svg');
                                /* Asume que tienes una imagen de flecha */
                                background-repeat: no-repeat;
                                background-position: right 10px center;
                                /* Ajusta los 10px para mover la flecha a la izquierda */
                                padding-right: 30px;
                                /* Asegúrate de tener suficiente padding para el texto y la flecha */

                                &:focus {
                                    outline: none;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 400px) {
    .perfil {

        .contenido {

            .datosPerfil {

                .datosDinamicos {

                    form {

                        .campos {

                            .campo {

                                label {
                                    min-height: 3.6rem;
                                    display: flex;
                                    align-items: flex-end;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}