@use '../base/variables' as v;

header {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: v.$morado;

    .banner {
        position: relative; // Asegura que el pseudo-elemento se posicione correctamente
        display: flex;
        align-items: center;
        justify-content: center;

        .contenido {
            display: flex;
            justify-content: space-between;
            padding: 2rem;
            height: 80%;
            width: 84%;
            flex-direction: row-reverse;

            h2 {
                font-size: 6.4rem;
                text-align: center;
                hyphens: none;
                color: #EADBC8;

                z-index: 2;
                position: relative;
                max-width: 56rem;
                width: 100%;
                line-height: 6.5rem;
                font-weight: v.$black;
                margin: 0;
                // margin-bottom: 6rem;
                // margin-left: 6rem;
                display: flex;
                align-items: flex-start;
            }

            .child {
                position: relative;
                z-index: 2;
                max-width: 36.6rem;
                display: flex;
                align-items: flex-end;
                gap: 1.5rem;
                margin-left: 4rem;

                .contenidoChild {
                    display: flex;
                    align-items: center;
                    gap: 1.5rem;

                    img {
                        width: 4rem;
                        height: 4rem;
                        padding-right: 1.5rem;
                        border-right: 1px solid rgba(v.$blanco, 10%);
                    }

                    p {
                        margin: 0;
                        color: rgba(v.$blanco, 50%);
                        line-height: 2.3rem;
                        font-size: 1.6rem;
                        font-weight: 400;
                        text-align: left;
                        hyphens: none;
                    }
                }
            }
        }

        &.no-visible {
            display: none;
        }

        &.visible {
            height: 58rem;
            width: 100%;
            background-image: url('/build/img/banner.jpg');
            background-position: center center;
            background-repeat: no-repeat;
            background-size: cover;

            // Agrega el pseudo-elemento para la sombra
            &::after {
                content: ""; // Necesario para que el pseudo-elemento se muestre
                position: absolute; // Posiciona el pseudo-elemento sobre el .banner.visible
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                background-color: rgba(0, 0, 0, 0.65); // Color negro con 65% de opacidad
                z-index: 1; // Asegura que la sombra esté sobre la imagen pero debajo del contenido
            }
        }
    }

    .barra-navegacion {
        position: relative;
        max-width: 100vw;
        width: 100%;
        min-height: 10rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 2rem;

        .logo {
            display: flex;
            gap: 2.5rem;
            justify-content: center;
            align-items: center;

            img {
                max-width: 5rem;
                width: 100%;
                height: 5.7rem;
            }

            p {
                color: v.$blanco;
                font-weight: v.$black;
                font-size: 2.4rem;
                text-transform: uppercase;
                margin: 0;
                max-width: 15.6rem;
                width: 100%;
                height: 3.4rem;
            }
        }

        .abrir-menu {
            border-radius: 50%;
            border: none;
            width: 4rem;
            height: 4rem;
            background-color: v.$blanco;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0;

            &:hover {
                cursor: pointer;
            }

            img {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 2rem;
                height: 1.4rem;
                aspect-ratio: 1/1;
                object-fit: cover;
            }
        }

        .navegacion {
            display: none;
            position: absolute;
            top: 0;
            right: 0;

            &.activo {
                display: flex;
                flex-direction: column;
                justify-content: center;
                min-height: 40rem;
                max-width: 26rem;
                width: 100%;

                &::backdrop {
                    backdrop-filter: blur(20px);
                }

                .cerrar-menu {
                    background-color: v.$blanco;
                    border-radius: 50%;
                    border: none;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 4rem;
                    height: 4rem;
                    padding: 0;
                    margin-right: 2rem;
                    margin-top: 3rem;
                    z-index: 20;

                    position: absolute;
                    top: 0;
                    right: 0;

                    &:hover {
                        cursor: pointer;
                    }

                    img {
                        width: 1.6rem;
                        height: 1.6rem;
                        aspect-ratio: 1/1;
                        object-fit: cover;
                    }
                }

                ul {
                    height: 35rem;
                    margin: 0;
                    padding: 0;
                    width: 100%;
                    // padding-top: 8rem;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    gap: .5rem;
                    background-color: v.$blanco;
                    z-index: 10;

                    transform: translateY(7.52rem);
                    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), // Sombra inferior
                        4px 0 6px rgba(0, 0, 0, 0.1), // Sombra derecha
                        -4px 0 6px rgba(0, 0, 0, 0.1);


                    li {
                        list-style: none;
                        display: flex;
                        justify-content: flex-start;
                        max-width: 15rem;
                        width: 100%;

                        &:last-child {
                            margin-top: 4rem;

                            &.perfil {
                                a {
                                    max-width: 5rem;
                                    height: 5rem;
                                    width: 100%;

                                    img {
                                        width: 5rem;
                                        height: 5rem;
                                        aspect-ratio: 1/1;
                                        object-fit: cover;
                                        background-color: transparent;
                                    }
                                }
                            }

                            &:hover {
                                a {
                                    color: v.$blanco;
                                }
                            }

                            a {
                                background-color: transparent;
                                color: v.$blanco;
                                height: 4rem;
                                width: 11rem;
                                font-size: 1.4rem;
                                font-weight: v.$black;
                                text-align: center;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                border-radius: 5rem;
                            }

                            &.login {

                                a {
                                    background-color: v.$morado;
                                }
                            }
                        }

                        &:hover {

                            a {
                                color: v.$negro;
                            }
                        }

                        a {
                            font-size: 2rem;
                            font-weight: v.$bold;
                            color: rgba(v.$negro, 50%);
                            line-height: 2.8rem;
                            text-align: left;
                            hyphens: none;
                        }
                    }
                }
            }
        }
    }
}

.contenido-envios {

    .envio-seleccionado {
        background-color: v.$verdeClaro;
    }
}

form {
    display: flex;
    flex-direction: column;
    gap: 2rem;

    .campo {
        display: flex;
        flex-direction: column;
        gap: .8rem;
    }
}

@media screen and (max-width: 868px) {
    header {

        .banner {

            .contenido {
                display: flex;
                flex-direction: column;

                h2 {
                    display: flex;
                    justify-content: flex-end;
                    margin-left: auto;
                }

                .child {
                    margin-left: 6rem;
                }
            }
        }
    }
}

@media screen and (max-width: 480px) {
    header {

        .banner {

            .contenido {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: 14rem;

                h2 {
                    display: flex;
                    justify-content: flex-end;
                    margin-left: auto;
                    font-size: 4.8rem;
                }

                .child {
                    margin: 0;
                }
            }
        }
    }
}