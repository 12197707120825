// Tipografia
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+3:ital,wght@0,200..900;1,200..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cabin:ital,wght@0,400..700;1,400..700&family=Source+Sans+3:ital,wght@0,200..900;1,200..900&display=swap');
$fuente_principal: "Source Sans 3", sans-serif;
$cabin: "Cabin", sans-serif;

// Colores
$rojo: #F53756;
$negro: #000000;
$blanco: #FFFFFF;
$verdeClaro: #1AACAC;
$morado: #756AB6;
$rojoClaro: #F28585;
$rosado: #E78895;


// Fuentes
$delgada: 300;
$regular : 400;
$bold: 700;
$black: 900;