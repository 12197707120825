@use '../base/variables' as v;

.nosotros {
    margin-top: 10rem;

    h2 {
        text-align: center;
        hyphens: none;
        max-width: 60rem;
        width: 100%;
        font-family: v.$cabin;
        font-size: 3.6rem;
        color: rgba(v.$negro, 50%);
        font-weight: v.$bold;
        margin: 0 auto;
        margin-bottom: 10rem;
    }

    .mutilacion {
        margin-bottom: 2.5rem;

        h3 {
            text-align: left;
            hyphens: none;
            font-size: 3.6rem;
            font-weight: v.$black;

            span {
                color: v.$morado;
            }
        }

        .contenido {
            .primero {
                font-family: v.$cabin;
                margin: 0;
                font-size: 1.6rem;
                height: 7.6rem;
                line-height: 1.9rem;
                margin-bottom: 2.5rem;
                text-align: justify;
                hyphens: none;
                font-weight: v.$regular;
            }

            .segundo {
                height: 17.1rem;
                font-family: v.$cabin;
                margin: 0;
                font-size: 1.6rem;
                line-height: 1.9rem;
                text-align: justify;
                hyphens: none;
                font-weight: v.$regular;
                margin-top: 1.4rem;
                display: flex;
                align-items: center;

                br {
                    margin-bottom: 2rem;
                }
            }

            .textoImagen {
                display: flex;
                gap: 2.5rem;

                .imagen {
                    max-width: 37rem;
                    width: 100%;
                    height: 30rem;
                    border-radius: 1rem;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        border-radius: 1rem;
                        aspect-ratio: 370/300;
                        border-radius: 1rem;
                    }
                }

                .texto {
                    max-width: 50.5rem;
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    gap: 1.5rem;

                    p {
                        font-family: v.$cabin;
                        margin: 0;
                        font-size: 1.6rem;
                        height: auto;
                        line-height: 1.9rem;
                        hyphens: none;
                        text-align: justify;

                        &:first-child {
                            height: 7.5rem;
                        }

                        &:last-child {
                            height: 20.9rem;
                        }
                    }
                }
            }
        }
    }

    .mapas {


        img {
            width: 90rem;
            height: 30rem;
            border-radius: 2rem;
            object-fit: cover;
        }
    }

    .multinacion {
        display: grid;
        grid-template-areas:
            "a b"
            "c b"
            "d e"
            "f f"
        ;

        column-gap: 3rem;
        row-gap: 1.5rem;
        margin-top: 2.5rem;
        margin-bottom: 2.5rem;

        p {
            font-family: v.$cabin;
            font-size: 1.6rem;
            margin: 0;
            hyphens: none;
            text-align: justify;
            font-weight: v.$regular;
            line-height: 1.9rem;

            &:first-child {
                grid-area: a;
            }

            &:nth-child(2) {
                grid-area: b;
            }

            &:nth-child(3) {
                grid-area: c;
            }

            &:nth-child(4) {
                grid-area: d;
            }

            &:nth-child(5) {
                grid-area: e;
            }

            &:last-child {
                grid-area: f;
            }
        }
    }

    .mali {
        height: 18.8rem;
        width: 100%;
        padding: 1rem 2.5rem 0 0;
        border-top: 5px solid #D9D9D9;
        border-bottom: 5px solid #D9D9D9;
        margin-bottom: 10rem;

        h5 {
            margin: 0;
            color: v.$morado;
            font-size: 2.4rem;
            font-weight: v.$bold;
            font-family: v.$cabin;
            margin-bottom: 1rem;
        }

        p {
            margin: 0;
            line-height: 1.9rem;
            font-size: 1.6rem;
            font-family: v.$cabin;
            hyphens: none;
        }
    }

    .espacioMujer {
        display: flex;
        background-color: v.$morado;
        height: 25rem;
        justify-content: center;
        align-items: center;
        border-radius: 1rem;
        gap: 5.5rem;
        margin-bottom: 2.5rem;

        .imagen {
            max-width: 21rem;
            width: 100%;
            height: 21rem;
            // border-radius: 2rem;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                aspect-ratio: 270/270;
                border-radius: 2rem;
            }
        }

        .texto {
            max-width: 50rem;
            width: 100%;

            h5 {
                font-size: 3.6rem;
                font-weight: v.$black;
                color: v.$blanco;
                text-align: left;
                hyphens: none;
                margin: 0;
                margin-bottom: 1rem;
            }

            p {
                margin: 0;
                font-size: 1.6rem;
                font-family: v.$cabin;
                font-weight: v.$regular;
                line-height: 1.9rem;
                hyphens: none;
                text-align: justify;
                color: v.$blanco;
            }
        }
    }

    .listaEspacioMujer {

        margin-bottom: 10rem;

        p {
            margin: 0;
            font-size: 1.6rem;
            hyphens: none;
            font-family: v.$cabin;
            font-weight: v.$regular;
            line-height: 1.9rem;
            text-align: justify;
        }

        .textoImagen {
            display: flex;
            gap: 2.5rem;
            margin-bottom: 2rem;

            .texto {
                max-width: 56rem;
                height: 22.1rem;

                p {
                    padding-left: .6rem;
                    display: flex;
                    flex-direction: row;
                    align-items: flex-start;
                    gap: 1rem;
                    margin: 0;
                    font-size: 1.6rem;
                    hyphens: none;
                    font-family: v.$cabin;
                    text-align: justify;
                    line-height: 1.9rem;
                    margin-bottom: 1.4rem;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }

            .imagen {
                max-width: 31.5rem;
                width: 100%;
                height: 25rem;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    aspect-ratio: 315/250;
                    border-radius: 1rem;
                }
            }
        }
    }

    .lenguaCatalana {
        margin-bottom: 10rem;

        h3 {
            margin: 0;
            height: 5.1rem;
            font-size: 3.6rem;
            font-weight: v.$black;
        }

        hr {
            margin: 0;
            border: none;
            height: .5rem;
            background-color: #D9D9D9;
            margin-bottom: 2.5rem;
        }

        .contenido {
            .titulo {
                margin: 0;
                color: v.$morado;
                font-size: 2.4rem;
                font-family: v.$cabin;
                font-weight: v.$bold;
                hyphens: none;
                text-align: left;
                margin-bottom: 1rem;
            }

            .texto {
                margin: 0;
                hyphens: none;
                text-align: justify;
                font-size: 1.6rem;
                font-family: v.$cabin;
                font-weight: v.$regular;
                line-height: 1.9rem;
                margin-bottom: 2rem;

                &.primero {
                    margin-bottom: 2.4rem;
                }
            }
        }
    }

    .costura {
        margin-bottom: 10rem;

        h3 {
            font-size: 3.6rem;
            font-weight: v.$black;
            margin: 0;
            text-align: left;
            hyphens: none;
            height: 5.1rem;
        }

        hr {
            margin: 0;
            height: .5rem;
            background-color: #D9D9D9;
            border: none;
            margin-bottom: 2.5rem;
        }

        .titulo {
            font-size: 2.4rem;
            font-weight: v.$bold;
            font-family: v.$cabin;
            text-align: left;
            hyphens: none;
            margin: 0;
            color: v.$rosado;
            margin-bottom: 1rem;
        }

        .texto {
            margin: 0;
            font-size: 1.6rem;
            font-family: v.$cabin;
            font-weight: v.$regular;
            line-height: 1.9rem;
            hyphens: none;
            text-align: justify;

            &.primerParrafo {
                margin-bottom: 2rem;
            }

            &.segundoParrafo {
                margin-bottom: 3rem;
            }
        }

        .tabla {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-template-rows: repeat(3, 1fr);
            gap: 1.5rem;
            max-width: 78rem;
            width: 100%;
            margin: 0 auto;
            margin-bottom: 5rem;

            div {
                background-color: v.$rosado;
                border-radius: 1rem;
                display: flex;
                justify-content: center;
                align-items: center;
                height: 7rem;
                width: 25rem;
                flex-direction: column;

                hr {
                    margin: 0;
                    border-top: 3px dotted rgba(v.$negro, 25%);
                    width: 100%;
                    background-color: transparent;

                    &.primero {
                        top: 0;
                    }

                    &.primero,
                    &.cuarto {
                        border-top: 1px solid rgba(v.$negro, 25%);
                        transform: translateY(.16rem);
                        clip-path: polygon(6px 0, calc(100% - 7.4px) 0, 100% 100%, 0 100%);
                    }

                    &.segundo,
                    &.tercero {
                        max-width: 24.6rem;
                    }

                    &.tercero {
                        transform: translateY(.6rem);
                    }

                    &.cuarto {
                        clip-path: polygon(0 0, 100% 0, calc(100% - 6px) 100%, 6.6px 100%);
                        border-top: 1px solid rgba(v.$negro, 25%);
                        transform: translateY(.5rem);
                        max-width: 24.2rem;
                    }
                }

                p {
                    margin: 0;
                    font-size: 1.6rem;
                    font-style: italic;
                    font-family: v.$cabin;
                    color: v.$blanco;
                    font-family: 100;
                    hyphens: none;
                    text-align: center;
                    line-height: 1.9rem;
                    height: 3.6rem;
                    font-weight: v.$regular;
                    scale: .9;

                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
        }
    }

    .proyectoMali {
        margin-bottom: 10rem;

        p {
            margin: 0;
            font-size: 1.6rem;
            font-family: v.$cabin;
            font-weight: v.$regular;
            line-height: 1.9rem;
            hyphens: none;
            text-align: justify;
        }

        .ilustracion {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #eee;
            border-radius: 1rem;
            gap: 5.5rem;
            height: 25rem;
            margin-bottom: 2.5rem;

            .imagen {
                width: 18rem;
                height: 16.6rem;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    aspect-ratio: 180/166;
                }
            }

            .texto {
                max-width: 47rem;

                h5 {
                    margin: 0;
                    font-size: 3.6rem;
                    font-weight: v.$black;
                    hyphens: none;
                    text-align: left;
                    margin-bottom: 1rem;
                }

                p {
                    margin: 0;
                    font-size: 1.6rem;
                    font-family: v.$cabin;
                    font-weight: v.$regular;
                    line-height: 1.9rem;
                    hyphens: none;
                    text-align: justify;
                }
            }
        }
    }

    .cultural {
        margin-bottom: 10rem;

        h3 {
            margin: 0;
            font-size: 3.6rem;
            font-weight: v.$black;
            hyphens: none;
            height: 5.1rem;
            text-align: left;
        }

        hr {
            margin: 0;
            height: .5rem;
            background-color: #D9D9D9;
            border: none;

            &.primero {
                margin-bottom: 2.5rem;
            }
        }

        .arabe {
            margin-top: 1rem;

            h5 {
                margin: 0;
                font-size: 2.4rem;
                font-weight: v.$bold;
                font-family: v.$cabin;
                hyphens: none;
                text-align: left;
                color: v.$morado;
                margin-bottom: 1rem;
            }

            p {
                margin: 0;
                font-size: 1.6rem;
                font-family: v.$cabin;
                font-weight: v.$regular;
                line-height: 1.9rem;
                hyphens: none;
                text-align: justify;
                margin-bottom: 2.5rem;
            }
        }

        .convivencia {
            margin-top: 1rem;

            h5 {
                margin: 0;
                font-size: 2.4rem;
                font-weight: v.$bold;
                font-family: v.$cabin;
                hyphens: none;
                text-align: left;
                color: v.$morado;
                margin-bottom: 1rem;
            }

            p {
                margin: 0;
                font-size: 1.6rem;
                font-family: v.$cabin;
                font-weight: v.$regular;
                line-height: 1.9rem;
                hyphens: none;
                text-align: justify;
                margin-bottom: 2.5rem;
            }
        }

        .salidas {
            margin-top: 1rem;

            h5 {
                margin: 0;
                font-size: 2.4rem;
                font-weight: v.$bold;
                font-family: v.$cabin;
                hyphens: none;
                text-align: left;
                color: v.$morado;
                margin-bottom: 1rem;
            }

            p {
                margin: 0;
                font-size: 1.6rem;
                font-family: v.$cabin;
                font-weight: v.$regular;
                line-height: 1.9rem;
                hyphens: none;
                text-align: justify;
                margin-bottom: 2.5rem;
            }
        }

        .danza {
            h5 {
                margin: 0;
                font-size: 2.4rem;
                font-weight: v.$bold;
                font-family: v.$cabin;
                hyphens: none;
                text-align: left;
                color: v.$morado;
                margin-bottom: 1rem;

            }

            p {
                margin: 0;
                font-size: 1.6rem;
                font-family: v.$cabin;
                font-weight: v.$regular;
                line-height: 1.9rem;
                hyphens: none;
                text-align: justify;
                margin-bottom: 2.5rem;
            }

            .textoImagen {
                display: flex;
                gap: 2.5rem;
                align-items: center;
                margin-bottom: 1.5rem;

                .imagen {
                    max-width: 32rem;
                    height: 20rem;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        aspect-ratio: 320/200;
                        border-radius: 1rem;
                    }
                }

                .texto {
                    max-width: 55.5rem;

                    p {
                        margin: 0;
                        hyphens: none;
                        font-size: 1.6rem;
                        font-family: v.$cabin;
                        font-weight: v.$regular;
                        line-height: 1.9rem;
                        text-align: justify;
                    }
                }
            }
        }
    }

    .educacion {
        margin-bottom: 10rem;

        h3 {
            margin: 0;
            font-size: 3.6rem;
            font-weight: v.$black;
            hyphens: none;
            height: 5.1rem;
            text-align: left;
        }

        p {
            margin: 0;
            font-size: 1.6rem;
            font-family: v.$cabin;
            font-weight: v.$regular;
            line-height: 1.9rem;
            hyphens: none;
            text-align: justify;

            &.segundoParrafo {
                margin-top: 2.5rem;
                margin-bottom: 2rem;
            }

            &.tercerParrafo {
                margin-top: 2rem;
                margin-bottom: 2.5rem;
            }

            &.cuartoParrafo {
                margin-bottom: 2.5rem;
            }
        }

        .tabla {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: auto;
            gap: 1.5rem;

            div {
                width: 41.5rem;
                background-color: v.$rosado;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 1rem;
                height: 8rem;
                gap: 2.5rem;

                &:nth-child(2),
                &:nth-child(4) {
                    width: 47rem;

                    p {
                        max-width: 33.5rem;
                        width: 100%;
                    }
                }

                img {
                    width: 5.5rem;
                    height: 5.5rem;
                }

                p {
                    margin: 0;
                    color: v.$blanco;
                    font-size: 1.6rem;
                    font-family: v.$cabin;
                    font-weight: v.$regular;
                    line-height: 1.9rem;
                    hyphens: none;
                    text-align: left;
                    max-width: 28.5rem;
                }
            }
        }
    }

    .digital {
        display: flex;
        background-color: v.$morado;
        height: 25rem;
        border-radius: 1rem;
        justify-content: center;
        align-items: center;
        gap: 5.5rem;
        margin-bottom: 10rem;

        .imagen {
            width: 21rem;
            height: 21rem;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                aspect-ratio: 210/210;
            }
        }

        .texto {
            max-width: 50rem;

            h5 {
                margin: 0;
                font-size: 3.6rem;
                font-weight: v.$black;
                color: v.$blanco;
                text-align: left;
                hyphens: none;
                margin-bottom: 1rem;
            }

            p {
                margin: 0;
                font-size: 1.6rem;
                font-family: v.$cabin;
                font-weight: v.$regular;
                line-height: 1.9rem;
                hyphens: none;
                text-align: justify;
                color: v.$blanco;

            }
        }
    }

    .registro {

        h3 {
            margin: 0;
            font-size: 3.6rem;
            font-weight: v.$black;
            hyphens: none;
            height: 5.1rem;
            text-align: center;
            width: 100%;
        }

        p {
            margin: 0;
            font-size: 1.6rem;
            font-family: v.$cabin;
            font-weight: v.$regular;
            line-height: 1.9rem;
            hyphens: none;
            text-align: center;
            width: 100%;
            margin-bottom: 3.5rem;

            span {
                font-weight: v.$bold;
            }
        }

        ul {
            display: flex;
            flex-direction: column;
            gap: .5rem;
            padding: 0;

            li {
                list-style: none;
                display: flex;
                gap: .5rem;

                p {
                    font-family: v.$cabin;
                    font-size: 2rem;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    text-align: left;
                    background-color: #eee;
                    border-radius: 1rem;
                    margin: 0;

                    &:first-child {
                        width: 60rem;
                        padding: 0 2.5rem;
                        font-size: 1.6rem;
                    }

                    &:nth-child(2),
                    &:nth-child(3),
                    &:nth-child(4),
                    &:nth-child(5) {
                        width: 6rem;
                        height: 4rem;
                        text-align: center;
                        font-size: 1.6rem;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                }

                &:first-child {
                    p {
                        font-size: 2rem;

                        &:first-child {
                            height: 4.5rem;
                            width: 60rem;
                            padding: 0 2.5rem;
                        }

                        &:last-child {
                            background-color: v.$morado;
                            color: v.$blanco;
                            text-align: center;
                            justify-content: center;
                            width: 25.5rem;
                        }
                    }
                }

                &:nth-child(2) {

                    p {

                        &:first-child {
                            background-color: transparent;
                        }
                    }
                }

                &:last-child {

                    p {
                        background-color: v.$morado;
                        color: v.$blanco;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 720px) {

    .nosotros {

        .registro {
            max-width: 60rem;
            width: 100%;
            margin: 0 auto;

            ul {
                li {

                    &:first-child {

                        p {

                            &:last-child {
                                min-width: 22rem;
                            }
                        }
                    }

                    &:nth-child(3) {

                        p {

                            &:nth-child(2),
                            &:nth-child(3),
                            &:nth-child(4),
                            &:nth-child(5) {
                                height: initial;
                            }
                        }

                    }

                    p {
                        &:first-child {
                            padding: .6rem 2rem;
                            min-width: 35rem;
                        }

                        &:nth-child(2),
                        &:nth-child(3),
                        &:nth-child(4),
                        &:nth-child(5) {
                            padding: .6rem;
                            min-width: 5rem;
                        }
                    }
                }
            }
        }

        .digital {
            max-width: 60rem;
            width: 100%;
            margin: 0 auto;
            margin-bottom: 10rem;

            display: flex;
            flex-direction: column;
            min-height: 53.5rem;
            height: auto;

            .texto {
                padding: 0 2rem;
            }
        }

        .educacion {
            max-width: 60rem;
            margin: 0 auto;
            margin-bottom: 10rem;

            .tabla {
                display: grid;
                grid-template-columns: 1fr;
                max-width: 47rem;
                width: 100%;
                margin: 0 auto;

                div {
                    max-width: 47rem;
                    width: 100%;
                    display: flex;
                    justify-content: flex-start;

                    padding: 1.1rem 2rem;
                }
            }
        }

        .cultural {
            max-width: 60rem;
            margin: 0 auto;
            margin-bottom: 10rem;

            .danza {
                .textoImagen {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;

                    .imagen {
                        height: 37.5rem;
                        max-width: 60rem;

                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                            aspect-ratio: 600/375;

                        }
                    }

                    .texto {
                        max-width: 60rem;
                    }
                }
            }
        }

        .proyectoMali {
            max-width: 60rem;
            margin: 0 auto;
            margin-bottom: 10rem;

            .ilustracion {
                height: auto;
                display: flex;
                flex-direction: column;
                min-height: 45.3rem;

                .texto {
                    height: auto;
                    padding: 0 2rem;

                    h5 {
                        height: auto;
                    }

                    p {
                        height: auto;
                    }
                }
            }
        }

        .costura {
            max-width: 60rem;
            margin: 0 auto;
            height: auto;
            margin-bottom: 10rem;

            .tabla {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                max-width: 51.5rem;
                width: 100%;
                height: auto;
            }
        }

        .lenguaCatalana {
            max-width: 60rem;
            margin: 0 auto;
            margin-bottom: 10rem;
        }

        .listaEspacioMujer {
            max-width: 60rem;
            margin: 0 auto;
            margin-bottom: 2.5rem;

            .textoImagen {
                display: flex;
                flex-direction: column;

                .texto {
                    height: auto;
                    max-width: 60rem;
                    width: 100%;

                    p {
                        height: auto;
                        max-width: 60rem;
                        width: 100%;
                    }
                }

                .imagen {
                    max-width: 60rem;
                    width: 100%;
                    height: 47.6rem;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        aspect-ratio: 600/476;
                        border-radius: 1rem;
                    }
                }
            }
        }

        .espacioMujer {
            max-width: 60rem;
            width: 100%;
            margin: 0 auto;
            margin-bottom: 2.5rem;
            min-height: 51.6rem;
            height: auto;

            display: flex;
            flex-direction: column;

            .texto {
                max-width: 50rem;
                height: auto;
                width: 100%;
                padding: 2rem;

                h5 {
                    height: auto;
                }

                p {
                    height: auto;
                }
            }
        }

        .mali {
            max-width: 60rem;
            margin: 0 auto;
            margin-bottom: 10rem;
            height: auto;
            padding-right: 0;
            padding-bottom: 1rem;

            p {
                height: auto;
            }
        }

        .multinacion {
            max-width: 60rem;
            margin: 2.5rem auto;
            display: flex;
            flex-direction: column;

            p {
                height: auto;
            }
        }

        .mapas {

            img {
                width: 60rem;
                height: 30rem;
            }
        }

        .mutilacion {
            max-width: 60rem;
            margin: 0 auto;
            margin-bottom: 2.5rem;

            .contenido {
                .primero {
                    height: auto;
                }

                .segundo {
                    height: auto;
                }

                .textoImagen {
                    display: flex;
                    flex-direction: column-reverse;
                    min-height: 70rem;
                    height: auto;

                    .imagen {
                        max-width: 60rem;
                        width: 100%;
                        height: 48.6rem;
                    }

                    .texto {
                        max-width: 60rem;
                        width: 100%;
                        height: auto;

                        p {
                            width: 100%;
                            height: auto;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 480px) {
    .nosotros {
        // width: 36rem;

        h2 {
            max-width: 36rem;
            text-align: left;
        }

        .registro {
            width: 36rem;

            h3 {
                height: auto;
            }

            ul {

                li {
                    height: auto;
                    
                    p {
                        font-size: 1.6rem;

                        &:first-child {
                            max-width: 14rem;
                            padding: 1rem;
                            height: auto;
                            min-width: auto;
                        }

                        &:nth-child(2),
                        &:nth-child(3),
                        &:nth-child(4),
                        &:nth-child(5),
                        &:nth-child(6),
                        &:nth-child(7),
                        &:nth-child(8),
                        &:nth-child(9),
                        &:nth-child(10),
                        &:nth-child(11),
                        &:nth-child(12),
                        &:nth-child(13),
                        &:nth-child(14),
                        &:nth-child(15),
                        &:nth-child(16),
                        &:nth-child(17),
                        &:nth-child(18),
                        &:nth-child(19),
                        &:nth-child(20) {
                            height: initial;
                        }

                    }
                }
            }
        }

        .digital {
            width: 36rem;
            padding: 4rem;
        }

        .educacion {
            width: 36rem;

            .tabla {

                div {
                    display: flex;
                    flex-direction: column;
                    height: auto;
                    max-width: 31.5rem;
                    margin: 0 auto;
                    padding: 1.5rem;

                    p {
                        text-align: left;
                    }
                }
            }
        }

        .cultural {
            width: 36rem;

            h3 {
                height: auto;
            }

            .danza {

                .textoImagen {

                    .imagen {
                        height: auto;

                        img {
                            height: 22.5rem;
                        }
                    }
                }
            }
        }

        .proyectoMali {
            width: 36rem;

            .ilustracion {
                padding: 5rem;

                .texto {
                    padding: 0;
                }
            }
        }

        .costura {
            width: 36rem;

            .tabla {
                display: grid;
                grid-template-columns: 1fr;

                div {
                    margin: 0 auto;
                }
            }
        }

        .lenguaCatalana {
            width: 36rem;
        }

        .listaEspacioMujer {
            width: 36rem;

            .textoImagen {

                .imagen {
                    max-height: 28rem;

                    img {
                        height: 28rem;
                    }
                }
            }
        }

        .espacioMujer {
            width: 36rem;

            padding: 5rem;

            .texto {
                max-width: 26rem;
                padding: 0;
            }
        }

        .mali {
            width: 36rem;

            p {
                height: auto;
            }
        }

        .multinacion {
            width: 36rem;

            p {
                height: auto;
            }
        }

        .mapas {
            width: 36rem;


            img {
                width: 36rem;
                height: 18rem;
            }
        }

        .mutilacion {
            width: 36rem;
            height: auto;

            .contenido {
                height: auto;

                .textoImagen {
                    height: auto;
                    min-height: auto;

                    .texto {
                        height: auto;
                        min-height: 40rem;

                        p {
                            height: auto;

                            &:first-child {
                                height: auto;
                            }
                        }
                    }

                    .imagen {
                        max-width: 36rem;
                        height: 29rem;
                    }
                }
            }
        }
    }
}