@use '../base/variables' as v;

.detallesPedido {
    margin-top: 6rem;

    .detalles {
        margin-top: 6rem;
        border: 1px solid lighten(v.$negro, 70);
        border-radius: .4rem;

        .barra {
            display: flex;
            gap: 4rem;
            background-color: lighten(v.$negro, 94);
            padding: 2rem;
            border-top-left-radius: .4rem;
            border-top-right-radius: .4rem;
            justify-content: space-between;
            border-bottom: 1px solid lighten(v.$negro, 70);

            .fecha,
            .total,
            .direccion,
            .numPedido {
                display: flex;
                flex-direction: column;
                color: rgba(v.$negro, .5);
                font-weight: v.$regular;
                gap: .6rem;
                hyphens: none;
                text-align: left;

                span {
                    font-weight: v.$bold;
                    color: v.$negro;
                }
            }

            .datos {
                display: flex;
                gap: 4rem;
            }
        }

        .productosEstado {
            margin: 0;
            padding: 2rem;
            border-bottom-left-radius: .4rem;
            border-bottom-right-radius: .4rem;

            .estadoPedido {
                h2 {
                    margin: 0;
                    font-weight: v.$regular;
                    color: rgba(v.$morado, .8);
                    padding: 2rem;
                }
            }

            .datosCliente {
                display: flex;
                justify-content: space-between;
                background-color: #eee;
                margin-top: 2rem;
                padding: 2rem;
                border-radius: .4rem;


                div {
                    display: flex;
                    flex-direction: column;
                    color: rgba(v.$negro, .5);
                    font-weight: v.$regular;
                    gap: .6rem;

                    span {
                        font-weight: v.$bold;
                        color: v.$negro;
                    }
                }
            }

            .productos {
                // margin-top: 4rem;
                display: flex;
                flex-direction: column;
                gap: 2rem;

                .producto {
                    display: flex;
                    background-color: lighten(v.$negro, 94);
                    padding: 1.4rem;
                    border-radius: .4rem;
                    justify-content: flex-start;
                    align-items: center;

                    .imagen {

                        img {
                            width: 12rem;
                            height: 12rem;
                            aspect-ratio: 1/1;
                            border-radius: .4rem;
                            margin-right: 2rem;
                            object-fit: cover;
                        }
                    }

                    .datos {
                        display: flex;
                        flex-direction: column;
                        gap: 1rem;

                        h3 {
                            margin: 0;
                            font-weight: bold;
                            hyphens: none;
                            text-align: left;
                        }

                        .precioCantidad {
                            p {
                                margin: 0;
                                display: flex;
                                color: rgba(v.$negro, .8);
                            }
                        }
                    }
                }
            }
        }

        .acciones {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            gap: 2rem;
            padding: 2rem;
            padding-top: 0;

            button {
                background-color: transparent;

                &:hover {
                    cursor: pointer;
                }
            }

            div {

                &[popover] {
                    border: none;
                    padding: 2rem;
                    border-radius: .4rem;
                    box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.2);
                    background-color: lighten(v.$negro, 96);
                    max-width: 30rem;
                    width: 100%;

                }

                &::backdrop {
                    backdrop-filter: blur(3px);
                }

                h2 {
                    margin: 0;
                    margin-bottom: 2rem;
                    hyphens: none;
                    font-size: 1.6rem;
                    text-align: center;
                }

                .accion {
                    display: flex;
                    gap: 1.5rem;
                    justify-content: center;
                    align-items: center;

                    button {
                        padding: 1rem;
                        border-radius: .4rem;
                        background-color: v.$rojoClaro;
                        color: v.$blanco;
                        font-weight: v.$bold;
                        border: none;
                        width: 10rem;

                        &:hover {
                            background-color: darken(v.$rojoClaro, 6);
                            cursor: pointer;
                        }
                    }

                    form {

                        button {
                            background-color: v.$verdeClaro;
                            border-radius: .4rem;
                            border: none;
                            padding: 1rem;
                            color: v.$blanco;
                            font-weight: v.$bold;
                            width: 10rem;

                            &:hover {
                                background-color: darken(v.$verdeClaro, 6);
                                cursor: pointer;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 730px) {
    .detallesPedido {

        .detalles {

            .barra {
                display: flex;
                flex-direction: column;

                .datos {
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                }
            }

            .acciones {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
            }
        }
    }
}

@media screen and (max-width: 600px) {
    .detallesPedido {

        .detalles {
            .productosEstado {
                .productos {
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                    justify-items: center;
                    align-items: center;

                    .producto {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        padding: .8rem;
                        max-width: 20rem;
                        gap: 1rem;

                        .imagen {
                            img {
                                margin: 0;
                                width: 18rem;
                                height: 18rem;
                                aspect-ratio: 1/1;
                            }
                        }

                        .datos {
                            h3 {
                                font-size: 1.6rem;
                            }

                            .precioCantidad {
                                p {
                                    font-size: 1.4rem;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 480px) {
    .detallesPedido {

        .detalles {

            .productosEstado {
                .productos {
                    display: grid;
                    grid-template-columns: repeat(1, 1fr);
                    justify-items: center;
                    align-items: center;

                    .producto {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        padding: .8rem;
                        max-width: 20rem;

                        .imagen {
                            img {
                                margin: 0;
                                width: 18rem;
                                height: 18rem;
                                aspect-ratio: 1/1;
                            }
                        }

                        .datos {
                            h3 {
                                font-size: 1.6rem;
                            }

                            .precioCantidad {
                                p {
                                    font-size: 1.4rem;
                                }
                            }
                        }
                    }
                }
            }

            .barra {
                display: flex;
                flex-direction: column;

                .datos {
                    display: grid;
                    grid-template-columns: repeat(1, 1fr);
                }
            }

            .acciones {
                display: grid;
                grid-template-columns: repeat(1, 1fr);

                div {
                    &[popover] {
                        max-width: 30rem;
                    }
                }
            }
        }
    }

}

h1 {
    text-align: center;
    margin-bottom: 6rem;
}

.formulario {
    margin-top: 4rem;

    input[type="submit"] {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    fieldset {
        border: none;
        display: flex;
        flex-direction: column;
        margin-bottom: 4rem;
        padding: 0;

        .campo {
            display: flex;
            flex-direction: column;
        }

        .precio {
            display: flex;
            align-items: flex-end;
            gap: 2rem;
            margin-top: 2rem;

            .campo {
                display: flex;
                flex-direction: column;

                input {
                    margin: 0;
                }
            }

            .divisa {
                font-weight: v.$bold;
            }
        }

        #divDiagnostico {
            display: flex;
            flex-direction: column;
            margin-bottom: 2rem;

            button {
                margin-bottom: 2rem;
            }
        }

        label {
            margin-bottom: 1rem;
            font-weight: v.$bold;
        }

        input {
            padding: 1rem;
            margin-bottom: 2rem;
            border: 1px solid lighten(v.$negro, 40);
            border-radius: .4rem;

            &:last-child {
                margin-bottom: 0;

            }
        }

        textarea {
            resize: none;
            height: 20rem;
            padding: 1rem;
        }

        select {
            padding: 1rem;
        }
    }
}

.alerta {
    margin-top: 2rem;
    padding: 1rem;
    color: v.$blanco;
    text-align: center;
    font-weight: v.$bold;
    text-transform: uppercase;
    border-radius: .6rem;

    &.error {
        background-color: lighten(v.$rojo, 16);
        color: darken(v.$rojo, 10);
        margin-bottom: 1rem;
        border-left: 8px solid v.$rojo;

        &:last-child {
            margin-bottom: 4rem;
        }
    }

    &.exito {
        background-color: lighten(v.$verdeClaro, 45);
        color: v.$verdeClaro;
        border-left: 8px solid v.$verdeClaro;
    }
}

label[for="stock"] {
    margin-top: 2rem;
}

.administrar {
    .contenido-administrar {
        margin-top: 4rem;

        &.paises {
            .articulo-administrar {
                h3 {
                    height: auto;
                    font-style: italic;
                    margin-bottom: 2rem;
                }
            }
        }

        &.usuarios {
            .articulo-administrar {
                h3 {
                    font-style: italic;
                    height: 4.6rem;
                }
            }
        }

        &.categorias {
            .articulo-administrar {
                h3 {
                    height: auto;
                    font-style: italic;
                }
            }
        }

        &.medicaciones {
            .articulo-administrar {
                h3 {
                    height: auto;
                }
            }
        }

        &.pedidos {
            .articulo-administrar {
                h3 {
                    height: auto;
                }
            }
        }

        &.envios {
            .articulo-administrar {
                h3 {
                    height: auto;
                }
            }
        }

        &.expedientes {
            .articulo-administrar {
                h3 {
                    height: auto;
                }
            }
        }

        &.diagnosticos {
            .articulo-administrar {
                h3 {
                    height: auto;
                }
            }
        }

        &.citas {
            .articulo-administrar {
                h3 {
                    height: 4.6rem;
                }
            }
        }

        &.productos {
            .articulo-administrar {
                .datos {
                    display: flex;
                    align-items: center;
                    gap: 2rem;
                    margin-bottom: 2rem;

                    img {
                        width: 8rem;
                        height: 8rem;
                        aspect-ratio: 1/1;
                        margin: 0;
                        border-radius: 50%;
                    }

                    .info {
                        display: flex;
                        flex-direction: column;
                        gap: .6rem;

                        h3,
                        p {
                            margin: 0;
                        }
                    }
                }
            }
        }

        &.articulos {
            .articulo-administrar {
                h3 {
                    height: 4.6rem;
                }
            }
        }

        &.doctores {
            .articulo-administrar {

                .datos {
                    display: flex;
                    align-items: center;
                    gap: 2rem;
                    margin-bottom: 2rem;

                    img {
                        width: 8rem;
                        height: 8rem;
                        aspect-ratio: 1/1;
                        border-radius: 50%;
                        margin: 0;
                    }

                    h3 {
                        margin-bottom: 0;
                        height: auto;
                        font-style: italic;
                        font-size: auto;
                    }
                }
            }
        }

        .articulo-administrar {
            background-color: lighten(v.$negro, 90);
            padding: 2rem;
            border-radius: .8rem;
            margin: 0;

            h3 {
                font-size: 1.8rem;
                text-align: left;
                margin-bottom: 2rem;
                max-height: 4.6rem;
                height: 100%;
            }

            .hora-cita {
                height: auto;
            }

            .nombre-usuario {
                height: auto;
            }

            .pais-cita {
                display: flex;
                flex-direction: column;
            }

            .forma-contacto {
                display: flex;
                flex-direction: column;
            }

            img {
                border-radius: .8rem;
                width: 100%;
                height: auto;
                aspect-ratio: 1/1;
                margin: auto;
                margin-bottom: 2rem;
                object-fit: cover;
            }

            .fecha {
                display: flex;
                justify-content: flex-end;
            }

            p {
                text-align: left;
                font-size: 1.4rem;
                margin-top: -1rem;
                max-height: 42px;
                display: flex;
                flex-direction: column;

                span {
                    font-weight: v.$bold;
                }
            }

            .acciones {
                display: flex;
                flex-direction: row-reverse;
                gap: 1rem;

                a {
                    width: 100%;
                }

                button {
                    height: 3.2rem;
                    width: 100%;
                    background-color: transparent;
                    color: lighten(v.$rojo, 16);
                    border: 1px solid lighten(v.$rojo, 16);
                }

                .accion {

                    .mensaje {
                        color: v.$negro;
                        font-family: v.$cabin;
                        font-weight: v.$bold;
                        text-align: center;
                        hyphens: none;
                        margin: auto;
                    }

                    &[popover] {
                        border: none;
                        max-width: 30rem;
                        border-radius: .4rem;
                        background-color: lighten(v.$negro, 96);
                        padding: 2rem;
                        box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.2);
                    }

                    &::backdrop {
                        backdrop-filter: blur(2px);
                    }

                    h3 {
                        hyphens: none;
                        text-align: center;
                        font-size: 1.6rem;
                    }

                    .botones {
                        display: flex;
                        gap: 1.5rem;
                        justify-content: center;
                        align-items: center;

                        button {
                            padding: 1rem;
                            border-radius: .4rem;
                            width: 8rem;
                            color: v.$blanco;
                            font-weight: v.$bold;
                            background-color: v.$rojoClaro;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            border: none;
                            font-size: 1.4rem;

                            &:hover {
                                background-color: darken(v.$rojoClaro, 6);
                                cursor: pointer;
                            }
                        }

                        form {
                            button {
                                padding: 1rem;
                                border-radius: .4rem;
                                width: 8rem;
                                color: v.$blanco;
                                font-weight: v.$bold;
                                background-color: v.$verdeClaro;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                border: none;
                                font-size: 1.4rem;

                                &:hover {
                                    background-color: darken(v.$verdeClaro, 6);
                                    cursor: pointer;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    @media screen and (min-width: 768px) {
        .contenido-administrar {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 2rem;
            /* Ajusta según el espacio entre los elementos */
        }
    }

    @media screen and (max-width: 767px) {
        .contenido-administrar {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 2rem;
            /* Ajusta según el espacio entre los elementos */
        }
    }

    @media screen and (max-width: 480px) {
        .contenido-administrar {
            display: grid;
            grid-template-columns: repeat(1, 1fr);
            gap: 2rem;
            /* Ajusta según el espacio entre los elementos */
        }
    }

    @media screen and (max-width: 838px) {
        .contenido-administrar {
            .articulo-administrar {
                h3 {
                    height: auto;
                }
            }
        }
    }
}


.img-small {
    width: 10rem;
    margin-bottom: 2rem;
}

.acciones {
    display: flex;
    flex-direction: column;

    &.filtro {
        display: flex;
        justify-content: space-between;
        flex-direction: row;

        .filtros {
            .busqueda {
                display: flex;
                gap: 2rem;

                form {
                    .campo {
                        display: flex;
                        flex-direction: column;

                        label {
                            font-weight: v.$bold;
                            margin-bottom: .4rem;
                        }

                        input {
                            padding: .6rem;
                        }

                        select {
                            background-color: transparent;
                            padding: .6rem;
                            border: 1px solid v.$negro;
                        }
                    }
                }
            }
        }
    }

    .volver {
        width: fit-content;
        margin-bottom: 2rem;
        color: v.$negro;
        display: flex;
        align-items: center;
        gap: .6rem;
    }

    .boton-crear {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: lighten(v.$negro, 90);
        padding: 2rem;
        border-radius: .4rem;

        img {
            width: 4rem;
            aspect-ratio: 1/1;
        }

        &:hover {
            background-color: lighten(v.$negro, 85);

            svg {
                fill: lighten(v.$negro, 40);
            }
        }
    }
}

@media screen and (max-width: 650px) {
    .acciones {
        &.filtro {
            flex-direction: column;

            .filtros {
                display: flex;
                justify-content: center;
                align-items: center;

                .busqueda {
                    flex-wrap: wrap;
                }
            }
        }

    }
}

.admin {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .acciones-admin {
        width: auto;
        max-width: 62rem;
        display: flex;
        flex-direction: row;
        gap: 2rem;
        background-color: #eee;
        padding: 1.2rem;
        border-radius: .4rem;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        object-fit: cover;

        .accion {

            p {
                display: flex;
                justify-content: center;
                align-items: center;
                margin-top: .6rem;
                font-weight: v.$bold;
                color: lighten(v.$negro, 70);

                a {
                    color: lighten(v.$negro, 70);

                    &:hover {
                        color: darken(v.$morado, 3);
                        cursor: pointer;
                    }
                }

                &:hover {
                    color: darken(v.$morado, 3);
                    cursor: pointer;
                }
            }
        }

        .boton-panel {
            font-weight: v.$bold;
            font-size: 2.2rem;
            background-color: lighten(v.$negro, 85);
            padding: 1.4rem;
            border-radius: .4rem;
            width: 10rem;
            height: 10rem;
            display: flex;
            justify-content: center;
            align-items: center;

            svg {
                fill: lighten(v.$negro, 96);
                width: 50px;
                height: 50px;
            }

            &:hover {
                background-color: darken(v.$morado, 3);
            }
        }
    }
}

.volver {
    color: v.$negro;
    display: flex;
    align-items: center;
    gap: .6rem;
    width: fit-content;
}

.contenido-articulo-individual {

    p {
        &:last-child {
            height: 42px;
        }
    }
}

.ver-todas {
    margin-top: 6rem;
    margin-bottom: 6rem;
    display: flex;
    justify-content: center;
    align-items: center;

    a {
        color: lighten(v.$negro, 40);
        font-weight: v.$bold;
    }
}

@media screen and (max-width: 640px) {
    .detallesPedido {
        .detalles {
            .productosEstado {
                .datosCliente {
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                    gap: 2rem;

                    div {
                        hyphens: none;
                        text-align: left;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 445px) {
    .detallesPedido {
        .detalles {
            .productosEstado {
                .datosCliente {
                    display: grid;
                    grid-template-columns: repeat(1, 1fr);
                    gap: 2rem;

                    div {
                        hyphens: none;
                        text-align: left;
                    }
                }
            }
        }
    }
}

.acciones {

    &.fechaNumPedido {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        a {
            margin: 0;
        }

        button {
            display: flex;
            border: none;
            background-color: transparent;
            font-family: v.$cabin;
            font-size: 1.4rem;
            align-items: center;
            gap: .4rem;

            &:hover {
                cursor: pointer;
            }

            img {
                width: 2rem;
                height: 2rem;
                aspect-ratio: 1/1;
            }
        }
    }
}

.filtros {

    &[popover] {
        border: none;
        // width: 30rem;
        padding: 2rem;
        border-radius: 1rem;
        box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.2);
        background-color: lighten(v.$negro, 96);
        max-width: 30rem;
        width: 100%;
        margin: auto;

        a {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 1rem;
            border-radius: 1rem;
            font-weight: v.$bold;
            text-align: center;
            font-family: v.$cabin;
            color: rgba(v.$morado, .8);
            text-decoration: underline;
            font-size: 1.2rem;
        }

        form {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            max-width: 30rem;
            width: 100%;

            button[type="submit"] {
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;

                border: none;
                padding: 1rem;
                background-color: v.$morado;
                color: v.$blanco;
                font-weight: v.$bold;
                font-family: v.$cabin;
                text-align: center;
                border-radius: 1rem;

                &:hover {
                    background-color: darken(v.$morado, 6);
                    cursor: pointer;
                }
            }

            .campos {
                display: flex;
                flex-direction: column;
                gap: 2rem;
                width: 100%;

                input,
                select {
                    width: 100%;
                    background-color: lighten(v.$negro, 90);
                    border: none;
                    padding: 1rem;
                    border-radius: 1rem;
                    font-size: 1.2rem;
                    font-family: v.$cabin;

                    &:focus {
                        outline: none;
                    }

                    &::placeholder {
                        font-family: v.$cabin;
                        color: rgba(v.$negro, .5);
                        font-weight: v.$bold;
                    }

                    option {
                        font-family: v.$cabin;
                        font-weight: v.$bold;
                        font-size: 1.2rem;
                        color: rgba(v.$negro, .5);
                    }
                }
            }
        }
    }

    &::backdrop {
        backdrop-filter: blur(3px);
    }
}

.mensaje {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10rem;

    font-family: v.$cabin;
    font-weight: v.$bold;
    color: rgba(v.$negro, .5);
    font-size: 1.6rem;
}