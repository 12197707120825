@use '../base/variables' as v;

.inicio {
    margin-top: 15rem;

    .quienesSomos {
        width: 90rem;
        height: 29rem;
        background-color: v.$rosado;
        border-radius: 1rem;

        padding-left: 2rem;
        padding-right: 8rem;

        padding-top: 1.5rem;
        padding-bottom: 1.5rem;

        .contenido {
            width: 80rem;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 6.5rem;

            .imagen {
                max-width: 40rem;
                width: 100%;
                height: 26rem;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    border-radius: 1rem;
                }
            }

            .texto {
                max-width: 33.5rem;
                width: 100%;
                height: 17.2rem;

                h3,
                p {
                    color: v.$blanco;
                }

                h3 {
                    margin: 0;
                    font-size: 3.6rem;
                    font-weight: v.$black;
                    text-align: left;
                    hyphens: none;
                    max-width: 27.3rem;
                    width: 100%;
                    height: 5.1rem;
                }

                p {
                    margin: 0;
                    font-size: 1.6rem;
                    font-family: v.$cabin;
                    height: 11.1rem;
                    max-width: 33.5rem;
                    width: 100%;
                    line-height: 2.2rem;
                    hyphens: none;
                }
            }
        }
    }

    .labores {
        margin-top: 10rem;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 2.5rem;

        h3 {
            margin: 0;
            text-align: left;
            hyphens: none;
            font-size: 3.6rem;
            font-weight: v.$bold;
        }

        .tabla {
            padding: 1rem 0;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-template-rows: repeat(2, 1fr);
            height: 26rem;
            max-width: 90rem;
            width: 100%;

            border-top: 1px solid #D9D9D9;
            border-bottom: 1px solid #D9D9D9;

            div {
                max-width: 30rem;
                width: 100%;
                height: 12rem;
                display: flex;
                justify-content: center;
                align-items: center;
                // padding: 2rem 3.2rem;

                &:nth-child(1) {
                    border-right: 1px solid #D9D9D9;
                    border-bottom: 1px solid #D9D9D9;
                }

                &:nth-child(2) {
                    border-right: 1px solid #D9D9D9;
                    border-bottom: 1px solid #D9D9D9;
                }

                &:nth-child(3) {
                    border-bottom: 1px solid #D9D9D9;
                }

                &:nth-child(4) {
                    border-right: 1px solid #D9D9D9;
                }

                &:nth-child(5) {
                    border-right: 1px solid #D9D9D9;
                }


                p {
                    margin: 0;
                    width: 23.5rem;
                    height: 8rem;
                    font-family: v.$cabin;
                    font-size: 1.6rem;
                    hyphens: none;
                    line-height: 1.9rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    text-align: left;
                }
            }
        }
    }

    .actividades {
        margin: 10rem 0;

        h3 {
            font-size: 3.6rem;
            font-weight: v.$bold;
            margin: 0;
            text-align: left;
            hyphens: none;
            margin-bottom: 3.5rem;
            max-width: 54.1rem;
            width: 100%;
            height: 5.1rem;
        }

        .contenido {

            p {
                margin: 0;
                font-family: v.$cabin;
                line-height: 1.9rem;
                font-size: 1.6rem;

                span {
                    font-weight: v.$bold;
                }
            }

            hr {
                margin-top: 3.5rem;
                margin-bottom: 1.5rem;
                height: .5rem;
                background-color: #D9D9D9;
                border: none;
            }

            .texto {
                height: 14.7rem;
                margin-bottom: 2rem;

                p {
                    height: 14.7rem;
                    margin: 0;
                    font-size: 1.6rem;
                    hyphens: none;
                    font-family: v.$cabin;
                    line-height: 1.9rem;
                }
            }

            .listaActividades {

                h4 {
                    margin: 0;
                    font-size: 2.4rem;
                    color: v.$morado;
                    font-weight: v.$bold;
                    max-width: 45.4rem;
                    width: 100%;
                    height: 3.4rem;
                    margin-bottom: 2rem;
                    hyphens: none;
                    text-align: left;
                    font-family: v.$cabin;
                }

                .contenido {
                    margin-bottom: 4.5rem;

                    a {
                        padding-left: 1.8rem;
                        color: rgba(#378CE7, 80%);
                        font-style: italic;
                        text-decoration: underline;
                        font-size: 1.6rem;
                        font-family: v.$cabin;

                        &:hover {
                            color: rgba(#378CE7, 100%);
                        }
                    }

                    h5 {
                        margin: 0;
                        padding-bottom: 1rem;
                        font-size: 1.6rem;
                        font-family: v.$cabin;
                        font-weight: v.$bold;
                        border-bottom: 1px solid rgba(v.$negro, 25%);
                    }

                    ul {
                        margin: 0;
                        padding: 0;
                        margin-top: 1rem;
                        list-style: none; // Remueve el estilo de lista por defecto
                        padding-left: .4rem;
                        margin-bottom: 2.5rem;

                        li {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            position: relative; // Importante para posicionar correctamente el pseudoelemento
                            padding-left: 1.4rem;

                            &::before {
                                content: "•"; // Agrega un punto
                                position: absolute;
                                left: 0; // Ajusta la posición del punto según sea necesario
                                top: 50%; // Centra verticalmente el punto respecto al elemento li
                                transform: translateY(-50%); // Asegura que el punto esté centrado verticalmente
                                margin-right: 0.5rem; // Añade espacio entre el punto y el texto
                            }

                            p {
                                margin: 0;
                                font-size: 1.6rem;
                                font-family: v.$cabin;
                                text-align: left;
                                hyphens: none;
                            }

                            span {
                                font-size: 1.6rem;
                                color: rgba(v.$negro, 50%);
                                font-weight: 500;
                                line-height: 1.9rem;
                            }
                        }
                    }

                    .tablaCompleta {
                        display: grid;
                        grid-template-columns: repeat(2, 1fr);
                        grid-template-rows: auto;
                        row-gap: 3.5rem;
                        column-gap: 7rem;
                        margin-top: 4.5rem;

                        .tabla {
                            h5 {
                                text-align: left;
                                hyphens: none;
                            }

                            &:first-child,
                            &:nth-child(3),
                            &:nth-child(4),
                            &:nth-child(6) {
                                ul {
                                    li {
                                        p {
                                            max-width: 34.5rem;
                                        }
                                    }
                                }
                            }

                            &:nth-child(2) {
                                ul {
                                    li {
                                        p {
                                            max-width: 30rem;
                                        }
                                    }
                                }
                            }

                            &:nth-child(5) {
                                ul {
                                    li {
                                        p {
                                            max-width: 29.5rem;
                                        }
                                    }
                                }
                            }

                            &:last-child {
                                ul {
                                    li {

                                        &:last-child {
                                            span {
                                                text-align: right;
                                            }
                                        }

                                        p {
                                            max-width: 30.5rem;
                                        }
                                    }

                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .avisoTienda {
        background-color: v.$rosado;
        width: 90rem;
        height: 30rem;
        border-radius: 1rem;

        .contenido {
            display: flex;
            align-items: center;
            gap: 5rem;
            padding: 5rem 6rem;

            .texto {
                color: v.$blanco;
                max-width: 53rem;
                width: 100%;
                height: 15.6rem;

                h3 {
                    font-size: 3.6rem;
                    font-weight: v.$black;
                    max-width: 53rem;
                    height: 5.1rem;
                    text-align: left;
                    hyphens: none;
                    margin: 0;
                    margin-bottom: 1rem;
                }

                p {
                    margin: 0;
                    font-family: v.$cabin;
                    font-size: 1.6rem;
                    line-height: 1.9rem;
                    color: v.$blanco;
                    hyphens: none;
                }
            }

            .imagen {
                width: 20rem;
                height: 20rem;
                display: flex;
                align-items: center;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    aspect-ratio: 1/1;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }
    }

    .involucrate {
        max-width: 90rem;
        width: 100%;
        margin-top: 10rem;

        h3 {
            margin: 0;
            font-size: 3.6rem;
            font-weight: v.$bold;
            text-align: left;
            hyphens: none;
            margin-bottom: 2.5rem;
        }

        .textoFinal {
            margin: 0;
            max-width: 35rem;
            width: 100%;
            height: 3.8rem;
            font-size: 1.6rem;
            color: rgba(v.$negro, 50%);
            line-height: 1.9rem;
            font-weight: v.$bold;
            margin-bottom: .5rem;
        }

        a {
            color: rgba(#378CE7, 80%);
            font-style: italic;
            text-decoration: underline;
            font-size: 1.6rem;
            font-family: v.$cabin;

            &:hover {
                color: rgba(#378CE7, 100%);
            }
        }

        .contenido {
            height: 31rem;
            padding: 2.5rem 0;
            display: flex;
            align-items: center;
            gap: 5rem;
            border-top: 1px solid rgba(v.$negro, 10%);
            border-bottom: 1px solid rgba(v.$negro, 10%);
            margin-bottom: 2rem;

            .imagen {
                max-width: 40rem;
                width: 100%;
                height: 26rem;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    border-radius: 1rem;
                }
            }

            .texto {
                max-width: 45rem;
                height: 13.8rem;
                width: 100%;

                h5 {
                    margin: 0;
                    font-size: 2rem;
                    color: v.$morado;
                    font-weight: v.$bold;
                    width: 9rem;
                    height: 2.8rem;
                }

                p {
                    font-size: 1.6rem;
                    font-family: v.$cabin;
                    line-height: 1.9rem;
                    hyphens: none;
                    margin: 0;
                    margin-bottom: 1.5rem;
                }
            }
        }
    }
}

@media screen and (max-width: 720px) {
    .inicio {

        .involucrate {
            max-width: 60rem;
            margin: 0 auto;
            margin-top: 10rem;

            .contenido {
                height: auto;
                display: flex;
                flex-direction: column;
                gap: 2rem;

                .imagen {
                    max-width: 60rem;
                    width: 100%;
                    min-height: 39.1rem;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        border-radius: .5rem;
                    }
                }

                .texto {
                    max-width: 60rem;

                    p {
                        width: 100%;
                    }
                }
            }
        }

        .avisoTienda {
            max-width: 60rem;
            width: 100%;
            min-height: 50.6rem;
            height: auto;
            margin: 0 auto;

            .contenido {
                min-height: 50.6rem;
                display: flex;
                flex-direction: column-reverse;

                .texto {
                    height: auto;

                    h3 {
                        height: auto;
                    }

                    p {
                        height: auto;
                    }
                }
            }
        }

        .actividades {
            max-width: 60rem;
            margin: 0 auto;
            margin-bottom: 10rem;

            h3 {
                margin: 0;
            }

            .contenido {

                .texto {

                    p {
                        min-height: 15.2rem;

                        br {
                            margin: 0;
                            display: none;
                        }
                    }


                }

                .listaActividades {


                    .contenido {
                        max-width: 48rem;
                        margin: 0 auto;

                        ul {
                            li {
                                p {
                                    max-width: 29.5rem;
                                }
                            }
                        }

                        .tablaCompleta {
                            display: flex;
                            flex-direction: column;
                        }
                    }
                }
            }
        }

        .labores {
            max-width: 60rem;
            margin: 10rem auto;
            min-height: 45.6rem;

            h3 {
                margin-bottom: 2.5rem;
            }

            .tabla {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                grid-template-rows: repeat(3, 1fr);
                border-bottom: 1px solid #D9D9D9;
                min-height: 38rem;

                div {

                    &:nth-child(2),
                    &:nth-child(4) {
                        border-right: none;
                    }

                    &:nth-child(3) {
                        border-right: 1px solid #D9D9D9;
                        border-bottom: 1px solid #D9D9D9;
                    }

                    &:nth-child(4) {
                        border-bottom: 1px solid #D9D9D9;
                    }

                    // &:nth-child(5),
                    // &:nth-child(6) {
                    //     border-bottom: 1px solid #D9D9D9;
                    // }
                }
            }
        }

        .quienesSomos {
            max-width: 60rem;
            width: 100%;
            height: auto;
            margin: 0 auto;
            padding: 1.5rem 2rem 4.5rem 2rem;
            min-height: 58.8rem;

            .contenido {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                max-width: 56rem;
                width: 100%;
                gap: 2.5rem;

                .imagen {
                    max-width: 56rem;
                    width: 100%;
                    min-height: 36.6rem;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        border-radius: .5rem;
                    }
                }

                .texto {
                    max-width: 42rem;
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    gap: 1rem;

                    h3 {
                        width: 100%;
                    }

                    p {
                        min-width: 100%;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 480px) {

    .inicio {

        .actividades {
            max-width: 36.1rem;

            h3 {
                height: auto;
            }

            .contenido {

                .texto {
                    height: auto;

                    p {
                        height: auto;
                    }
                }

                .listaActividades {

                    .contenido {

                        ul {
                            li {
                                display: flex;
                                align-items: flex-start;

                                p {
                                    max-width: 20rem;
                                }
                            }
                        }

                        .tablaCompleta {

                            .tabla {

                                &:first-child,
                                &:nth-child(2),
                                &:nth-child(3),
                                &:nth-child(4),
                                &:nth-child(5),
                                &:nth-child(6),
                                &:last-child {
                                    ul {
                                        li {
                                            display: flex;
                                            align-items: flex-start;

                                            p {
                                                max-width: 20rem;
                                            }

                                            span {
                                                max-width: 8rem;
                                                text-align: right;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .labores {
            max-width: 36.1rem;

            .tabla {
                display: grid;
                grid-template-columns: 1fr;
                height: auto;

                div {
                    max-width: 36.1rem;
                    width: 100%;

                    &:first-child,
                    &:nth-child(3),
                    &:nth-child(5) {
                        border-right: none;
                    }

                    &:nth-child(5) {
                        border-bottom: 1px solid #D9D9D9;
                    }
                }
            }
        }

        .quienesSomos {
            max-width: 36.1rem;
            min-height: auto;

            .contenido {

                .imagen {
                    all: initial;
                    max-width: 32rem;
                    width: 100%;
                    height: 20.9rem;

                    img {
                        height: 20.9rem;

                    }
                }
            }
        }
    }
}