@use '../base/variables' as v;

.articulos {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    max-width: 79rem;
    width: 100%;
    margin: 0 auto;
    gap: 2rem;
    margin-top: 10rem;

    .articulo {
        max-width: 25rem;
        width: 100%;
        height: 37rem;

        .img {
            max-height: 25rem;
            max-width: 25rem;
            border-radius: 1.5rem;
            margin-bottom: 1rem;

            img {
                width: 100%;
                height: 25rem;
                object-fit: cover;
                border-radius: 1.5rem;
                aspect-ratio: 1/1;
            }
        }

        .contenido {
            max-width: 23rem;
            width: 100%;

            h3 {
                margin: 0;
                font-size: 2rem;
                font-weight: v.$black;
                height: 3rem;
                color: rgba(v.$negro, .8);
            }

            p {
                margin: 0;
                font-family: v.$cabin;
                font-size: 1.4rem;
                hyphens: none;
                height: 8rem;
                color: rgba(v.$negro, .5);
                text-align: left;
            }
        }
    }
}

// Vista articulo individual en la página de detalles
.detallesArticulo {

    .regresar {
        max-width: 76rem;
        width: 100%;
        margin: 0 auto;
        margin-top: 8rem;
        margin-bottom: 8rem;

        a {
            height: 2rem;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: .5rem;

            p {
                margin: 0;
                font-family: v.$cabin;
                font-size: 1.4rem;
                font-weight: v.$bold;
            }

            img {
                aspect-ratio: 1/1;
                width: 2rem;
                height: 2rem;
            }
        }
    }

    .articulo {
        max-width: 65rem;
        width: 100%;
        min-height: 80.6rem;
        margin: 0 auto;

        h3 {
            line-height: 4rem;
            font-size: 3.6rem;
            color: rgba(v.$negro, .8);
            width: 100%;
            font-weight: v.$black;
            hyphens: none;
            text-align: left;
            margin: 0;
            margin-bottom: 1rem;
        }

        .autor-fecha {
            max-width: 59.7rem;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            height: 1.9rem;
            margin-bottom: 2.5rem;

            div {
                border: 1px solid rgba(v.$negro, .25);
                width: 1.2px;
                height: 1.5rem;
            }

            p {
                margin: 0;
                display: flex;
                gap: .5rem;
                font-size: 1.6rem;
                color: rgba(v.$negro, .5);
                font-family: v.$cabin;
                hyphens: none;
                text-align: left;

                &:nth-child(1) {
                    padding-right: 1.5rem;
                }

                &:last-child {
                    padding-left: 1.5rem;
                }
            }
        }

        .imagen {
            width: 100%;
            margin-bottom: 2.5rem;

            img {
                width: 100%;
                height: 40rem;
                object-fit: cover;
                border-radius: .5rem;
                aspect-ratio: 650/400;
            }
        }

        .desc {
            width: 100%;
            font-family: v.$cabin;
            display: flex;
            flex-direction: column;
            gap: 1rem;
            color: v.$negro;

            p {
                text-align: left;
                hyphens: none;
                font-size: 1.6rem;
                margin: 0;
                line-height: 2.1rem;
            }
        }
    }
}

@media screen and (max-width: 845px) {
    .articulos {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        justify-items: center;
        align-items: center;
        max-width: 56rem;
    }
}

@media screen and (max-width: 600px) {
    .articulos {
        display: grid;
        grid-template-columns: 1fr;
        max-width: 26rem;

        .articulo {

            .contenido {
                h3 {
                    height: auto;
                    hyphens: none;
                    text-align: left;
                }
            }
        }
    }
}

@media screen and (max-width: 550px) {
    .detallesArticulo {
        .articulo {

            .imagen {

                img {
                    height: auto;
                    width: 100%;
                    aspect-ratio: 16/9;
                }
            }

            .autor-fecha {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                margin-bottom: 4rem;

                div {
                    display: none;
                }

                p {

                    &:nth-child(1) {
                        padding-right: 0;
                    }

                    &:last-child {
                        padding-left: 0;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 360px) {
    .detallesArticulo {
        .articulo {

            h3 {
                font-size: 3rem;
                line-height: 3.2rem;
            }

            .autor-fecha {

                p {
                    font-size: 1.4rem;
                }
            }
        }
    }
}

@media screen and (max-width: 310px) {
    .detallesArticulo {
        .articulo {

            h3 {
                font-size: 2.2rem;
                line-height: 3rem;
            }

            .autor-fecha {

                p {
                    font-size: 1.2rem;
                }
            }
        }
    }
}