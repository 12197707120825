@use '../base/variables' as v;

.tienda {

    .encabezado {
        max-width: 76rem;
        width: 100%;
        height: 3rem;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding-top: 6rem;
        padding-bottom: 10rem;
        margin: 0 auto;

        h1 {
            font-weight: v.$black;
            font-size: 2rem;
            text-align: left;
            hyphens: none;
            color: rgba(v.$negro, .65);
            text-transform: uppercase;
            margin: 0;
            height: 2.8rem;
        }

        .carrito {
            position: relative;
            height: 3rem;
            /* Asegura que el posicionamiento absoluto sea relativo a .carrito */

            a {
                width: 2.76rem;
                height: 2.734rem;
                display: inline-block;
                /* Asegura que se comporte como un contenedor */
                position: relative;
                /* Necesario para que el hijo absoluto se posicione respecto a este */
            }

            .cantidad {
                background-color: v.$rojoClaro;
                /* Cambia esto a v.$rojoClaro en tu proyecto */
                border-radius: 50%;
                width: 1.5rem;
                height: 1.5rem;
                position: absolute;
                top: -0.40rem;
                /* Ajusta esto según sea necesario */
                right: -0.60rem;
                /* Ajusta esto según sea necesario */
                display: flex;
                align-items: center;
                justify-content: center;
                color: white;
                /* Color del texto dentro de .cantidad */
                font-size: 0.75rem;
                /* Tamaño del texto dentro de .cantidad */

                p {
                    color: v.$blanco;
                    font-size: 1rem;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-weight: v.$black;
                }
            }
        }
    }

    .productos {
        max-width: 79rem;
        margin: 0 auto;

        .contenido-productos {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 2rem;

            .producto {
                display: flex;
                flex-direction: column;
                background-color: #EEEEEE;
                border-radius: 2rem;
                height: 42rem;
                width: 25rem;

                .imagen {
                    max-height: 24rem;
                    height: 100%;
                    padding-top: .45rem;
                    padding-right: .5rem;
                    padding-left: .5rem;
                    width: 100%;

                    img {
                        border-radius: 2rem;
                        height: 24rem;
                        width: 100%;
                        object-fit: cover;
                        aspect-ratio: 1/1;
                    }
                }

                h3 {
                    margin: 0;
                    margin-top: 1.5rem;
                    max-width: 20rem;
                    width: 100%;
                    height: 2rem;
                    margin-right: auto;
                    margin-left: auto;
                }

                p {
                    margin: 0;
                    width: 20rem;
                    height: 6rem;
                    margin: 0 auto;
                    margin-top: .5rem;

                    p {
                        font-family: v.$cabin;
                        font-size: 1.2rem;
                        font-weight: v.$regular;
                        text-align: left;
                        hyphens: none;
                        color: rgba(v.$negro, .5);
                        width: 20rem;
                        height: 6rem;
                        line-height: 1.5rem;
                        // overflow: hidden;
                    }
                }

                .botones-precio {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    max-width: 20rem;
                    height: 4rem;
                    margin: 0 auto;
                    gap: .5rem;
                    margin-top: 1.5rem;

                    .botones {
                        display: flex;
                        gap: .5rem;

                        a {
                            width: 4rem;
                            height: 4rem;
                            // background-color: v.$morado;
                            border-radius: 50%;
                            background-image: url('/build/img/verProducto.svg');
                            background-position: center center;
                            background-repeat: no-repeat;
                            border: none;
                            background-size: 104%;
                        }

                        button {
                            background-color: v.$negro;
                            width: 4rem;
                            height: 4rem;
                            background-image: url('/build/img/cesta.svg');
                            background-position: center center;
                            background-repeat: no-repeat;
                            border: none;
                            border-radius: 50%;
                            background-size: 50%;
                            padding: 0;

                            &:hover {
                                cursor: pointer;
                            }
                        }
                    }

                    p {
                        width: 11rem;
                        height: 3rem;
                        font-size: 2.4rem;
                        font-weight: v.$black;
                        padding: 0;
                        text-align: center;
                        margin: 0;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        color: rgba(v.$negro, .65);

                        .decimales {
                            font-size: 1.4rem;
                            display: flex;
                            align-items: flex-start;
                            height: 100%;
                            font-weight: v.$bold;
                        }
                    }
                }
            }
        }
    }
}

// Diseño vista producto individual
.detalleProducto {

    .encabezado {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .carrito {
            position: relative;
            height: 3rem;
            /* Asegura que el posicionamiento absoluto sea relativo a .carrito */

            a {
                width: 2.76rem;
                height: 2.734rem;
                display: inline-block;
                /* Asegura que se comporte como un contenedor */
                position: relative;
                /* Necesario para que el hijo absoluto se posicione respecto a este */
            }

            .cantidad {
                background-color: v.$rojoClaro;
                /* Cambia esto a v.$rojoClaro en tu proyecto */
                border-radius: 50%;
                width: 1.5rem;
                height: 1.5rem;
                position: absolute;
                top: -0.40rem;
                /* Ajusta esto según sea necesario */
                right: -0.60rem;
                /* Ajusta esto según sea necesario */
                display: flex;
                align-items: center;
                justify-content: center;
                color: white;
                /* Color del texto dentro de .cantidad */
                font-size: 0.75rem;
                /* Tamaño del texto dentro de .cantidad */
                font-weight: v.$black;

                p {
                    color: v.$blanco;
                    font-size: 1rem;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-weight: v.$black;
                }
            }
        }
    }

    .producto {
        max-width: 55rem;
        width: 100%;
        margin: 0 auto;

        .imagen {
            max-width: 55rem;
            width: 100%;
            height: 35rem;
            margin-bottom: 2rem;
            box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.3);
            border-radius: .5rem;
            border: none;

            img {
                display: flex;
                justify-content: center;
                align-items: center;

                height: 35rem;
                max-width: 55rem;
                width: 100%;
                object-fit: cover;
                border-radius: .5rem;
                border: none;
            }
        }

        .descripcion {
            max-width: 55rem;
            width: 100%;
            margin: 0;
            font-size: 1.6rem;
            font-family: v.$cabin;
            text-align: left;
            hyphens: none;
            margin-top: 2.5rem;

            p {
                margin: 0;
                font-size: 1.6rem;
                font-family: v.$cabin;
                text-align: left;
                hyphens: none;
                line-height: 2rem;
            }
        }

        .masDetalles {
            height: 11.5rem;
            max-width: 55rem;
            width: 100%;
            background-color: #eee;
            border-radius: 1rem;
            display: flex;
            flex-direction: column;
            gap: .5rem;

            .contenido {
                max-width: 52rem;
                width: 100%;
                height: 8.5rem;
                margin: auto;
                display: flex;
                flex-direction: column;
                gap: .5rem;

                .botones {
                    display: flex;
                    justify-content: space-between;
                    height: 4rem;
                    max-width: 52rem;
                    width: 100%;
                    align-items: center;

                    .separacion {
                        border: none;
                        background-color: rgba(v.$negro, .10);
                        height: 3rem;
                        width: .1rem;
                    }

                    .mensaje {
                        max-width: 21.5rem;
                        width: 100%;
                        height: 3.6rem;
                        margin: 0;
                        text-align: left;

                        p {
                            height: 100%;
                            width: 100%;
                            margin: 0;
                            font-size: 1rem;
                            font-family: v.$cabin;
                            hyphens: none;
                            text-align: justify;
                            color: rgba(v.$negro, .5);
                            line-height: 1.2rem;
                        }
                    }

                    .acciones {
                        display: flex;
                        flex-direction: row;
                        gap: 1rem;

                        .agregar-carrito {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            padding: 0;
                            margin: 0;

                            width: 15rem;
                            // width: 100%;
                            height: 4rem;
                            background-color: v.$negro;
                            color: v.$blanco;

                            font-size: 1.2rem;
                            font-weight: v.$black;
                            border: none;
                            border-radius: 10rem;
                            hyphens: none;
                            text-align: left;

                            &:hover {
                                cursor: pointer;
                            }

                            img {
                                margin-left: .8rem;
                                width: 1.6rem;
                                height: 1.6rem;
                                aspect-ratio: 1/1;
                            }
                        }

                        .cantidad-producto {
                            display: flex;
                            max-width: 11.5rem;
                            width: 100%;
                            height: 4rem;
                            border-radius: 2rem;
                            background-color: #D9D9D9;
                            justify-content: center;
                            align-items: center;

                            .contenido {
                                max-width: 10.3rem;
                                width: 100%;
                                height: 3rem;
                                display: flex;
                                align-items: center;
                                gap: .2rem;
                                flex-direction: row;

                                span {
                                    width: 4rem;
                                    height: 3rem;
                                    border-radius: 10rem;
                                    background-color: v.$blanco;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;

                                    font-size: 1.2rem;
                                    font-family: v.$cabin;
                                    font-weight: v.$bold;
                                }

                                button {
                                    height: 3rem;
                                    width: 3rem;
                                    border-radius: 50%;
                                    border: none;
                                    background-color: v.$morado;
                                    color: v.$blanco;
                                    background-position: center center;
                                    background-repeat: no-repeat;
                                    background-size: 110%;
                                    padding: 0;
                                    margin: 0;

                                    &:hover {
                                        cursor: pointer;
                                    }

                                    &:first-child {
                                        background-image: url('/build/img/menos.svg');
                                    }

                                    &:last-child {
                                        background-image: url('/build/img/mas.svg');
                                    }
                                }
                            }
                        }
                    }
                }

                .nombrePrecio {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    h2 {
                        font-size: 2rem;
                        font-family: v.$cabin;
                        margin: 0;
                        font-weight: v.$bold;
                        hyphens: none;
                        text-align: left;
                        max-width: 44rem;
                        width: 100%;
                        line-height: 4rem;
                        color: v.$negro;
                    }

                    p {
                        margin: 0;
                        height: 1.9rem;
                        max-width: 8rem;
                        width: 100%;
                        font-size: 1.6rem;
                        font-family: v.$cabin;
                        font-weight: v.$bold;
                        color: rgba(v.$negro, .65);
                        hyphens: none;
                        text-align: left;
                    }
                }
            }
        }
    }

    .encabezado {
        display: flex;
        justify-content: space-between;
        max-width: 76rem;
        width: 100%;
        padding-top: 6rem;
        padding-bottom: 7.5rem;
        margin: 0 auto;

        .regresar {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: .5rem;

            img {
                width: 2.2rem;
                height: 1.7rem;
            }

            p {
                font-size: 1.4rem;
                font-weight: v.$bold;
            }
        }

        .carrito {
            img {
                width: 27.58px;
                height: 27.34px;
            }
        }
    }
}

.contenedor-carrito {
    .encabezado {
        display: flex;
        flex-direction: column;
        max-width: 76rem;
        width: 100%;
        // height: 8rem;
        height: auto;
        margin: 0 auto;
        margin-top: 6rem;
        margin-bottom: 10rem;
        gap: 3rem;

        a {
            display: flex;
            overflow: visible;
            align-items: center;
            gap: .5rem;
            max-width: 7.3rem;
            height: 2rem;
            width: 100%;

            img {
                width: 2.2rem;
                height: 1.7rem;
            }

            p {
                font-size: 1.4rem;
                font-weight: v.$bold;
                font-family: v.$cabin;
                hyphens: none;
                text-align: left;
            }
        }

        h2 {
            margin: 0;
            text-transform: uppercase;
            font-size: 2rem;
            font-weight: v.$black;
            height: 3rem;
            width: 100%;
            color: rgba(v.$negro, .65);
        }
    }

    .carrito {
        max-width: 40rem;
        width: 100%;
        margin: 0 auto;

        .finalizar-compra {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 1.5rem;

            .botones {
                display: flex;
                align-items: center;
                gap: .5rem;
                max-width: 24.5rem;
                width: 100%;
                justify-content: flex-end;
                flex-direction: row-reverse;

                a {
                    background-color: v.$morado;
                    color: v.$blanco;
                    height: 4rem;
                    max-width: 10rem;
                    width: 100%;
                    border-radius: 2rem;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 1.2rem;
                    font-weight: v.$black;

                    &:hover {
                        cursor: pointer;
                    }
                }

                div {
                    max-width: 14rem;
                    width: 100%;
                    height: 4rem;

                    button {
                        border: none;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        max-width: 14rem;
                        width: 100%;
                        height: 4rem;
                        border-radius: 10rem;
                        background-color: v.$rojoClaro;
                        gap: .2rem;

                        color: v.$blanco;
                        font-size: 1.2rem;
                        font-weight: v.$black;

                        &:hover {
                            cursor: pointer;
                        }

                        img {
                            width: 1.4rem;
                            height: 1.6rem;
                        }
                    }
                }
            }

            .mensaje-iva {
                height: 4rem;
                max-width: 12rem;
                width: 100%;

                p {
                    margin: 0;
                    display: flex;
                    flex-direction: column;
                    text-align: left;
                    hyphens: none;
                    font-family: v.$cabin;
                    font-size: 1rem;
                    color: rgba(v.$negro, .5);
                    max-width: 12rem;
                    width: 100%;

                    span {
                        font-weight: v.$bold;
                        color: v.$negro;
                    }
                }
            }
        }

        .mensaje {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            max-width: 40rem;
            width: 100%;
            height: 6.2rem;
            margin-top: 1.5rem;

            button {
                border: none;
                display: flex;
                align-items: center;
                justify-content: center;
                max-width: 14rem;
                width: 100%;
                height: 4rem;
                border-radius: 10rem;
                background-color: v.$rojoClaro;
                gap: .2rem;

                color: v.$blanco;
                font-size: 1.2rem;
                font-weight: v.$black;

                &:hover {
                    cursor: pointer;
                }

                img {
                    width: 1.4rem;
                    height: 1.6rem;
                }
            }

            .sessionRequired {
                display: flex;
                flex-direction: column;
                gap: .5rem;

                p {
                    max-width: 18rem;
                    margin: 0;
                    font-family: v.$cabin;
                    font-size: 1.4rem;
                    color: v.$negro;
                    hyphens: none;
                    text-align: left;
                    height: 4rem;
                }

                a {
                    font-size: 1.4rem;
                    font-weight: v.$bold;
                    color: v.$morado;
                    text-decoration: underline;
                }
            }
        }

        .subtotal {
            height: 3.9rem;
            max-width: 40rem;
            width: 100%;
            margin-top: 6rem;

            p {
                display: flex;
                justify-content: space-between;
                padding: 1rem 0;
                margin: 0;
                border-bottom: 1px solid rgba(v.$negro, .5);
                border-top: 1px solid rgba(v.$negro, .5);

                span {
                    font-size: 1.6rem;
                    font-weight: v.$bold;
                }
            }
        }

        .total {
            height: 3.9rem;
            max-width: 40rem;
            width: 100%;
            border-top: 1px solid rgba(v.$negro, .5);
            border-bottom: 1px solid rgba(v.$negro, .5);
            margin-top: 3rem;

            p {
                margin: 0;
                padding: 1rem 0;
                height: 3.9rem;
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;

                &:first-child {
                    text-align: left;
                }

                &:last-child {
                    text-align: right;
                }

                span {
                    font-size: 1.6rem;
                    font-weight: v.$bold;
                    font-family: v.$cabin;
                    hyphens: none;

                }
            }
        }

        .envios {
            margin-top: 3rem;
            display: flex;
            flex-direction: column;
            gap: 2rem;

            h2 {
                margin: 0;
                font-size: 1.6rem;
                font-weight: v.$bold;
                color: rgba(v.$negro, .5);
                font-family: v.$cabin;
                height: 1.9rem;
                max-width: 40rem;
                width: 100%;
                text-align: left;
                hyphens: none;
            }

            .contenido-envios {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                gap: 1rem;

                .envio {
                    background-color: #eee;
                    border-radius: 2rem;
                    height: 8rem;
                    max-width: 19.5rem;
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: center;
                    padding-left: 1.8rem;

                    box-shadow: 0 6px 2px -2px lighten(v.$negro, 74);

                    &:hover {
                        cursor: pointer;
                    }

                    &.envio-seleccionado {
                        background-color: v.$morado;

                        h3,
                        p {
                            color: v.$blanco;
                        }

                        .plazo {
                            color: #eee;
                        }
                    }

                    h3 {
                        margin: 0;
                        font-size: 1.6rem;
                        font-weight: v.$black;
                        color: rgba(v.$negro, .8);
                        text-align: left;
                        hyphens: none;
                    }

                    p {
                        margin: 0;
                    }

                    .plazo {
                        color: rgba(v.$negro, .55);
                        font-size: 1.2rem;
                        font-family: v.$cabin;
                    }

                    .precio {
                        color: rgba(v.$negro, .65);
                        font-size: 1.2rem;
                        font-family: v.$cabin;
                        font-weight: v.$bold;
                    }
                }
            }
        }

        .vacio {
            margin: 0 auto;
            max-width: 32.5rem;
            height: 10rem;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            gap: 1rem;
            text-align: center;
            hyphens: none;
            font-size: 1.4rem;
            color: rgba(v.$negro, .5);

            img {
                width: 3rem;
                height: 3rem;
                aspect-ratio: 1/1;
            }
        }

        .contenido-carrito {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 2rem;

            .producto {
                background-color: #EEEEEE;
                max-width: 40rem;
                width: 100%;
                height: 18rem;
                display: flex;
                border-radius: 2rem;
                align-items: center;
                gap: 1rem;

                .descripcion {
                    height: 15.5rem;
                    max-width: 20rem;
                    width: 100%;

                    h3 {
                        font-size: 2rem;
                        font-weight: v.$black;
                        margin: 0;
                        border-bottom: 1px solid rgba(v.$negro, 10%);
                        padding-bottom: .5rem;
                    }

                    .botones {
                        padding-top: 1rem;
                        display: flex;
                        gap: .5rem;

                        a {
                            height: 3rem;
                            width: 3rem;
                            background-color: v.$morado;
                            border-radius: 50%;

                            p {
                                color: v.$blanco;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                line-height: 2.4rem;
                                font-weight: v.$bold;
                                font-size: 1.6rem;
                                margin: 0;
                            }
                        }

                        .btn-eliminar {
                            width: 1rem;

                            background-image: url('/build/img/delete.svg');
                            background-position: center center;
                            background-repeat: no-repeat;
                            background-size: 60%;
                            border-radius: 50%;
                            border: none;
                            width: 3rem;
                            height: 3rem;
                            background-color: v.$rojoClaro;

                            &:hover {
                                cursor: pointer;
                                background-color: darken(v.$rojoClaro, 10);
                            }
                        }

                        .cantidad {
                            background-color: #D9D9D9;
                            border-radius: 5rem;
                            max-width: 9.5rem;
                            width: 100%;
                            height: 3rem;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            gap: .3rem;

                            button {
                                background-color: v.$morado;
                                border-radius: 50%;
                                color: v.$blanco;
                                font-weight: v.$bold;
                                width: 2.5rem;
                                height: 2.5rem;
                                border: none;
                                font-family: v.$fuente_principal;

                                &:first-child {
                                    background-image: url('/build/img/menos.svg');
                                }

                                &:last-child {
                                    background-image: url('/build/img/mas.svg');
                                }

                                &:hover {
                                    cursor: pointer;
                                }
                            }

                            span {
                                background-color: v.$blanco;
                                width: 3.5rem;
                                height: 2.5rem;
                                border-radius: 10rem;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                font-family: v.$cabin;
                            }
                        }
                    }

                    .desc-precio {
                        padding-top: .5rem;

                        .precio {
                            p {
                                margin: 0;
                                color: rgba(v.$negro, .65);
                                font-weight: v.$bold;
                                font-size: 1.4rem;
                                font-family: v.$cabin;

                                span {
                                    font-size: 1rem;
                                    text-transform: uppercase;
                                }
                            }
                        }

                        .desc {
                            padding-bottom: .5rem;
                            border-bottom: 1px solid rgba(v.$negro, 10%);

                            p {
                                font-size: 1.2rem;
                                text-align: left;
                                hyphens: none;
                                margin: 0;
                                color: rgba(v.$negro, .65);
                                font-family: v.$cabin;
                            }
                        }
                    }
                }

                .imagen {
                    padding-left: .6rem;

                    img {
                        max-width: 17rem;
                        width: 100%;
                        aspect-ratio: 1/1;
                        border-radius: 2rem;
                        height: 17rem;
                        object-fit: cover;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 840px) {
    .tienda {

        .encabezado {
            max-width: 60rem;
        }

        .productos {
            width: 100%;
            display: flex;
            justify-content: center;

            .contenido-productos {
                grid-template-columns: repeat(2, 1fr);
            }
        }
    }
}

@media screen and (max-width: 540px) {
    .tienda {

        .encabezado {
            max-width: 40rem;
        }

        .productos {
            width: 100%;
            display: flex;
            justify-content: center;

            .contenido-productos {
                grid-template-columns: 1fr;
            }
        }
    }
}

@media screen and (max-width: 800px) {
    .contenedor-carrito {
        .encabezado {
            max-width: 60rem;
        }
    }
}

@media screen and (max-width: 410px) {
    .contenedor-carrito {
        .carrito {

            .finalizar-compra {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: center;
            }
        }
    }
}

@media screen and (max-width: 550px) {
    .detalleProducto {
        .producto {
            .masDetalles {
                height: auto;

                .contenido {
                    height: auto;
                    padding: 1rem;
                    gap: 3rem;

                    .nombrePrecio {
                        display: flex;
                        align-items: flex-start;

                        h2 {
                            max-width: 34rem;
                            height: auto;
                            line-height: 2rem;
                        }

                        p {
                            font-size: 2rem;
                            height: auto;
                            text-align: right;
                        }
                    }

                    .botones {
                        height: auto;
                        display: flex;
                        flex-direction: column-reverse;
                        align-items: flex-start;
                        gap: 2rem;

                        .mensaje {
                            width: 100%;
                            max-width: 100%;

                            p {
                                width: 100%;
                                text-align: left;
                                hyphens: none;
                            }
                        }

                        .separacion {
                            display: none;
                        }

                        .acciones {

                            .cantidad-producto {
                               .contenido {
                                padding: 0;
                               }
                            }
                        }
                    }
                }
            }
        }
    }
}