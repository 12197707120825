@use '../base/variables' as v;

.boton-verde {
    background-color: v.$verdeClaro;
    border: none;
    color: v.$blanco;
    font-weight: v.$bold;
    padding: 1.2rem;
    text-align: center;
    border-radius: .4rem;

    &:hover {
        background-color: darken(v.$verdeClaro, 5);
        cursor: pointer;
    }
}

.boton-verde-block {
    display: block;
    background-color: v.$verdeClaro;
    border: none;
    color: v.$blanco;
    padding: 1.2rem;
    text-align: center;
    border-radius: .4rem;

    &:hover {
        background-color: darken(v.$verdeClaro, 5);
        cursor: pointer;
    }
}

.boton-rojo {
    background-color: v.$rojo;
    border: none;
    color: v.$blanco;
    padding: 1.2rem;
    text-align: center;
    border-radius: .4rem;

    &:hover {
        background-color: darken(v.$rojo, 5);
        cursor: pointer;
    }
}

.boton-verdeClaro {
    color: v.$verdeClaro;
    border: 1px solid v.$verdeClaro;
    padding: .6rem;
    border-radius: .4rem;
    position: relative;
    z-index: 1;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: v.$bold;

    &:hover {
        color: v.$blanco;
    }

    &::after {
        content: "";
        background: v.$verdeClaro;
        position: absolute;
        z-index: -1;
        padding: .6rem;
        display: block;
        left: -20%;
        right: -20%;
        top: 0;
        bottom: 0;
        transform: skewX(-45deg) scale(0, 1);
        transition: all 0.3s ease;
    }

    &:hover::after {
        transition: all 0.3s ease-out;
        transform: skewX(-45deg) scale(1, 1);
    }
}

.boton-rojoClaro {
    color: lighten(v.$rojo, 16);
    padding: .6rem;
    border-radius: .4rem;
    position: relative;
    z-index: 1;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: v.$bold;

    &:hover {
        cursor: pointer;
        svg {
            color: darken(v.$rojo, 10);
        }
    }

    &::after {
        content: "";
        // background: v.$rojo;
        background-color: lighten(v.$rojo, 16);
        position: absolute;
        z-index: -1;
        padding: .6rem;
        display: block;
        left: -50%;
        right: -50%;
        top: 0;
        bottom: 0;
        transform: skewX(-45deg) scale(0, 1);
        transition: all 0.3s ease;
    }

    &:hover::after {
        transition: all 0.3s ease-out;
        transform: skewX(-45deg) scale(1, 1);
    }
}