@use '../base/variables' as v;

.contacto {
    margin-top: 10rem;

    .contendorContacto {
        max-width: 45rem;
        width: 100%;
        // height: 67.8rem;
        background-color: #eee;
        margin: 0 auto;
        border-radius: .5rem;
        display: flex;
        justify-content: center;
        align-items: center;

        .contenidoContacto {
            max-width: 34rem;
            width: 100%;
            // height: 58.8rem;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 4.5rem;
            padding: 2rem;

            .form {
                // height: 29.5rem;
                max-width: 25rem;
                width: 100%;

                form {
                    display: flex;
                    flex-direction: column;
                    gap: 1.5rem;
                    height: auto;


                    button[type="submit"] {
                        background-color: v.$morado;
                        height: 4rem;
                        color: v.$blanco;
                        border-radius: 2rem;
                        font-size: 1.2rem;
                        font-family: v.$cabin;
                        font-weight: v.$bold;
                        border: none;
                        margin: 0;
                        padding: 0;

                        &:hover {
                            cursor: pointer;
                            background-color: darken(v.$morado, 2);
                        }
                    }

                    .campos {
                        display: flex;
                        flex-direction: column;
                        gap: 1rem;

                        .campo {

                            input,
                            textarea {
                                border: none;
                                background-color: v.$blanco;
                                height: 4rem;
                                border-radius: 1rem;
                                padding: 1rem;
                                font-size: 1.4rem;
                                font-family: v.$cabin;

                                &:focus {
                                    outline: none;
                                }

                                &::placeholder {
                                    font-size: 1.2rem;
                                    font-family: v.$cabin;
                                    font-weight: v.$bold;
                                    color: rgba(v.$negro, .5);
                                }
                            }

                            textarea {
                                resize: none;
                                height: 18rem;
                            }
                        }
                    }
                }
            }

            .encabezado {
                max-width: 34rem;
                width: 100%;
                height: 9.5rem;

                h3 {
                    font-family: v.$cabin;
                    font-size: 2.4rem;
                    font-weight: v.$bold;
                    hyphens: none;
                    text-align: left;
                    margin: 0;
                    color: rgba(v.$negro, .65);
                    margin-bottom: .5rem;
                    // height: 5.8rem;
                }

                p {
                    margin: 0;
                    font-size: 1.4rem;
                    font-family: v.$cabin;
                    color: rgba(v.$negro, .5);
                    margin-bottom: 1.5rem;
                    height: 1.7rem;
                }

                hr {
                    background-color: rgba(v.$negro, .1);
                    height: .1rem;
                    width: 100%;
                    border: none;
                    margin: 0;
                }
            }

            .footer {
                display: none;
                hr {
                    background-color: rgba(v.$negro, .1);
                    height: .1rem;
                    width: 100%;
                    border: none;
                    margin: 0;
                    margin-bottom: 1.5rem;
                }

                .texto {
                    width: 100%;
                    height: 3.8rem;
                    margin-bottom: 1.5rem;

                    p {
                        margin: 0;
                        font-family: v.$cabin;
                        font-size: 1.6rem;
                        hyphens: none;
                        text-align: left;
                        height: 100%;
                        color: rgba(v.$negro, .5);
                        line-height: 2rem;
                    }
                }

                .iconosTexto {
                    display: flex;
                    max-width: 33rem;
                    width: 100%;
                    height: 4rem;
                    align-items: center;
                    gap: 1.5rem;

                    .iconos {
                        display: flex;
                        max-width: 13rem;
                        width: 100%;
                        height: 4rem;
                        gap: .5rem;

                        img {
                            width: 4rem;
                            height: 4rem;
                            aspect-ratio: 1/1;
                        }
                    }

                    .separacion {
                        max-width: .1rem;
                        width: 100%;
                        border: none;
                        height: 2.4rem;
                        // margin-left: 1.5rem;
                        // margin-right: 1.5rem;
                        background-color: rgba(v.$negro, .1);
                    }

                    .texto {
                        height: 2.4rem;
                        max-width: 17rem;
                        width: 100%;
                        margin: auto 0;

                        p {
                            margin: 0;
                            height: 100%;
                            width: 17rem;
                            font-size: 1rem;
                            font-weight: 500;
                            hyphens: none;
                            text-align: left;
                            line-height: 1.3rem;
                        }
                    }
                }
            }
        }
    }
}

// @media screen and (max-width: 410px) {
//     .contacto {
//         .contendorContacto {
//             .contenidoContacto {
//                 padding: 0 1rem;

//                 .encabezado {
//                     h3 {
//                         font-size: 2rem;
//                         height: 5.8rem;
//                     }
//                 }

//                 .footer {
//                     .texto {
//                         p {
//                             font-size: 1.4rem;
//                             line-height: 1.8rem;
//                         }
//                     }
//                 }
//             }
//         }
//     }
// }