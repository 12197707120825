@use '../base/variables' as v;

.contenedorLogin {
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    .mensaje {
        direction: ltr;
        margin: auto;

        .recuperar {
            max-width: 25rem;
            width: 100%;
            height: 5.1rem;

            p {
                text-align: center;
                font-size: 1.4rem;
                font-family: v.$cabin;
                font-weight: v.$bold;
                height: 5.1rem;
                line-height: 1.7rem;
                hyphens: none;
            }
        }

        .recuperada {
            max-width: 28rem;
            width: 100%;
            height: 9.9rem;

            p {
                text-align: center;
                hyphens: none;
                line-height: 1.7rem;
            }
        }

        .verificar {
            max-width: 25rem;
            width: 100%;

            p {
                text-align: center;
                line-height: 1.7rem;
                hyphens: none;
            }
        }

        div {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 1rem;

            img {
                width: 3rem;
                height: 3rem;
                aspect-ratio: 1/1;
            }

            p {
                margin: 0;
                font-family: v.$cabin;
                font-weight: v.$bold;
                font-size: 1.4rem;
            }

            a {
                font-size: 1.2rem;
                font-family: v.$cabin;
                font-weight: v.$bold;
                text-decoration: underline;
            }
        }
    }

    &.contenedorSingUp {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        direction: rtl;

        .formularioSingUp {
            direction: ltr;
            max-width: 25rem;
            width: 100%;
            height: 37.5rem;
            margin: auto;

            .alerta {
                margin: 0;
                padding: 0;
                margin-bottom: 2.5rem;

                &.errorDatos {
                    height: 2rem;
                    max-width: 17.5rem;
                    display: flex;
                    gap: .5rem;
                    align-items: center;

                    img {
                        width: 2rem;
                        height: 2rem;
                        aspect-ratio: 1/1;
                    }

                    p {
                        margin: 0;
                        font-size: 1rem;
                        font-family: v.$cabin;
                        text-align: left;
                        hyphens: none;
                        color: rgba(v.$negro, .5);
                        line-height: 1.2rem;
                        text-transform: none;
                    }
                }
            }

            .mas {
                display: flex;
                align-items: center;
                max-width: 21.3rem;
                width: 100%;
                height: 2.5rem;
                margin: 0 auto;
                gap: 4.5rem;
                margin-top: 1.5rem;

                .iniciaSesion {
                    max-width: 8.3rem;
                    width: 100%;
                    height: 2.5rem;
                    line-height: .6rem;

                    p {
                        font-size: 1rem;
                        font-family: v.$cabin;
                        hyphens: none;
                        text-align: left;
                        margin: 0;
                        color: rgba(v.$negro, .5);
                    }

                    a {
                        font-size: 1rem;
                        font-family: v.$cabin;
                        hyphens: none;
                        text-align: left;
                        color: v.$morado;
                        text-decoration: underline;
                        font-weight: v.$bold;
                    }
                }

                .avisoCampos {
                    max-width: 8rem;
                    width: 100%;
                    height: 2.5rem;

                    p {
                        font-size: 1rem;
                        font-family: v.$cabin;
                        hyphens: none;
                        text-align: left;
                        margin: 0;
                        color: rgba(v.$negro, .5);
                        line-height: 1.3rem;
                    }
                }
            }

            form {
                padding: 0;
                margin: 0;
                display: flex;
                flex-direction: column;
                gap: 1rem;

                input[type="submit"] {
                    background-color: v.$morado;
                    border-radius: 2rem;
                    border: none;
                    height: 4rem;
                    max-width: 25rem;
                    width: 100%;

                    color: v.$blanco;
                    font-family: v.$cabin;
                    font-weight: v.$bold;
                    font-size: 1.4rem;

                    padding: 0;
                    margin: 0;

                    &:hover {
                        cursor: pointer;
                        background-color: darken(v.$morado, 2);
                    }

                    &.disabled {
                        opacity: .5;
                        cursor: not-allowed;
                    }
                }

                .avisoPoliticas {
                    max-width: 24rem;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    padding-left: 1rem;
                    gap: 1rem;
                    height: 2.5rem;
                    margin: .5rem 0;

                    input[type="radio"] {
                        width: 1.5rem;
                        height: 1.5rem;

                        &:checked {
                            background-color: v.$morado;
                        }

                        &:hover {
                            cursor: pointer;
                        }
                    }

                    a {
                        color: rgba(#378CE7, .8);
                        text-decoration: underline;
                    }

                    p {
                        margin: 0;
                        font-size: 1rem;
                        font-family: v.$cabin;
                        text-align: left;
                        hyphens: none;
                        color: rgba(v.$negro, .5);
                        max-width: 16.5rem;
                        width: 100%;
                    }
                }

                .campo {

                    .pass {
                        display: flex;
                        position: relative;

                        input {
                            flex: 1;
                        }

                        .mostrarPass {
                            background-color: transparent;
                            border: none;
                            color: v.$blanco;
                            cursor: pointer;
                            font-size: 1.4rem;
                            padding: .4rem;
                            width: fit-content;
                            position: absolute;
                            right: 10px;
                            top: 54%;
                            transform: translateY(-50%);
                            width: 2.4rem;
                            height: 2.4rem;
                        }
                    }

                    input,
                    select {
                        border: none;
                        height: 4rem;
                        max-width: 25rem;
                        width: 100%;
                        border-radius: 1rem;
                        background-color: #eee;
                        padding: 1rem;

                        font-family: v.$cabin;
                        font-size: 1.4rem;
                        font-weight: v.$bold;

                        &:focus {
                            outline: none;
                        }

                        &::placeholder {
                            font-family: v.$cabin;
                            font-size: 1.4rem;
                            font-weight: v.$bold;
                            color: rgba(v.$negro, .5);
                        }
                    }

                    select {
                        -webkit-appearance: none;
                        /* Safari y Chrome */
                        -moz-appearance: none;
                        /* Firefox */
                        appearance: none;
                        /* Estándar */
                        font-weight: v.$bold;

                        &::-ms-expand {
                            display: none;
                            /* Internet Explorer y Edge */
                        }

                        &:focus {
                            color: v.$negro;
                        }

                        &:hover {
                            cursor: pointer;
                        }

                        option,
                        &:first-child {
                            font-family: v.$cabin;
                            font-size: 1.4rem;
                            font-weight: v.$bold;
                            color: rgba(v.$negro, .3);

                            &:hover {
                                cursor: pointer;

                            }
                        }
                    }
                }

                .datos-personales {
                    display: flex;
                    gap: 1rem;

                    .campo {

                        input {
                            height: 4rem;
                            max-width: 12rem;
                            width: 100%;
                            border-radius: 1rem;
                            background-color: #eee;
                            border: none;
                            padding: 1rem;

                            font-family: v.$cabin;
                            font-size: 1.4rem;
                            font-weight: v.$bold;

                            &:focus {
                                outline: none;
                            }

                            &::placeholder {
                                font-family: v.$cabin;
                                font-size: 1.4rem;
                                font-weight: v.$bold;
                                color: rgba(v.$negro, .5);
                            }
                        }
                    }
                }
            }
        }
    }

    .formularioLogin {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        gap: 1.5rem;
        max-width: 25rem;
        margin: 0 auto;

        .alerta {

            &.errorDatos {
                text-transform: none;
            }
        }

        &.recuperar {
            max-width: 25rem;
            width: 100%;
            height: 14.4rem;
            margin: auto;

            form {
                display: flex;
                flex-direction: column;
                gap: 1.5rem;

                .campo {
                    display: flex;
                    gap: 1.5rem;
                    flex-direction: column;

                    label {
                        height: 3.4rem;
                        font-size: 1.4rem;
                        font-family: v.$cabin;
                        font-weight: v.$bold;
                        text-align: left;
                        hyphens: none;
                    }

                    .pass {
                        display: flex;
                        position: relative;

                        input {
                            flex: 1;
                            border: none;
                            height: 4rem;
                            background-color: #eee;
                            padding: 1rem;
                            border-radius: 1rem;

                            font-family: v.$cabin;
                            font-size: 1.4rem;
                            font-weight: v.$bold;
                            color: rgba(v.$negro, 50%);

                            &:focus {
                                outline: none;
                            }

                            &::placeholder {
                                font-family: v.$cabin;
                                font-size: 1.2rem;
                                font-weight: v.$bold;
                                color: rgba(v.$negro, 50%);
                            }
                        }

                        .mostrarPass {
                            background-color: transparent;
                            border: none;
                            color: v.$blanco;
                            cursor: pointer;
                            font-size: 1.2rem;
                            padding: .4rem;
                            width: fit-content;
                            position: absolute;
                            right: 10px;
                            top: 54%;
                            transform: translateY(-50%);
                            width: 2.4rem;
                            height: 2.4rem;
                        }
                    }
                }
            }
        }

        &.olvide {
            max-width: 25rem;
            height: 12rem;
            width: 100%;
            margin: auto;

            form {

                .alertaboton {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    max-width: 25rem;
                    height: 2rem;
                    width: 100%;

                    .alerta {
                        margin: 0;
                        display: flex;
                        gap: .5rem;
                        align-items: center;
                        text-transform: none;

                        &.errorDatos {
                            img {
                                width: 2rem;
                                height: 2rem;
                                aspect-ratio: 1/1;
                            }

                            p {
                                font-size: 1rem;
                                font-family: v.$cabin;
                                max-width: 15rem;
                                width: 100%;
                                height: 2rem;
                                line-height: 1rem;
                                text-align: left;
                                hyphens: none;
                                text-transform: none;
                                display: flex;
                                align-items: center;
                            }
                        }
                    }

                    .enviar {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        gap: .3rem;
                        margin-left: auto;

                        &:hover {
                            cursor: pointer;
                        }

                        input {
                            all: initial;
                            height: 1.4rem;
                            font-size: 1rem;
                            font-family: v.$cabin;
                            font-weight: v.$bold;

                            &:hover {
                                cursor: pointer;
                            }
                        }

                        img {
                            width: 1.1rem;
                            height: 1.1rem;
                            aspect-ratio: 1/1;
                        }
                    }
                }

                .campo {
                    display: flex;
                    gap: 1.5rem;

                    label {
                        height: 3rem;
                        text-align: left;
                        hyphens: none;
                        font-size: 1.2rem;
                        font-family: v.$cabin;
                        font-weight: v.$bold;
                    }

                    input {
                        border: none;
                        height: 4rem;
                        background-color: #eee;
                        padding: 1rem;
                        border-radius: 1rem;

                        font-family: v.$cabin;
                        font-size: 1.4rem;
                        font-weight: v.$bold;
                        color: v.$negro;

                        &:focus {
                            outline: none;
                        }

                        &::placeholder {
                            font-family: v.$cabin;
                            font-size: 1.2rem;
                            font-weight: v.$bold;
                            color: rgba(v.$negro, .5);

                        }
                    }
                }
            }
        }

        .alerta {
            margin: 0;
            padding: 0;
            margin-bottom: 2.5rem;

            &.errorDatos {
                height: 2rem;
                max-width: 17.5rem;
                display: flex;
                gap: .5rem;
                align-items: center;

                img {
                    width: 2rem;
                    height: 2rem;
                    aspect-ratio: 1/1;
                }

                p {
                    margin: 0;
                    font-size: 1rem;
                    font-family: v.$cabin;
                    text-align: left;
                    hyphens: none;
                    color: rgba(v.$negro, .5);
                    line-height: 1.2rem;
                }
            }
        }

        .botones {
            width: 23.1rem;
            height: 2.4rem;
            display: flex;
            margin: 0 auto;
            align-items: flex-start;
            gap: 4.5rem;

            .registro {
                height: 2.4rem;
                width: 9.6rem;
                line-height: 1rem;

                p,
                a {
                    font-size: 1rem;
                    font-family: v.$cabin;
                    hyphens: none;
                    text-align: left;
                    height: 1.2rem;
                }

                p {
                    margin: 0;
                    font-weight: v.$regular;
                    color: rgba(v.$negro, .5);
                }

                a {
                    max-width: 4.1rem;
                    width: 100%;
                    height: 1.2rem;
                    color: v.$morado;
                    text-decoration: underline;
                    font-weight: v.$bold;
                }
            }

            a {
                font-size: 1rem;
                width: 9rem;
                height: 1.2rem;
                font-family: v.$cabin;
                font-weight: v.$bold;
                text-align: left;
                hyphens: none;
                text-decoration: underline;
                color: v.$morado;
            }
        }

        form {
            height: 14.5rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 1.5rem;

            input[type="submit"] {
                background-color: v.$morado;
                height: 4rem;
                border-radius: 2rem;
                border: none;
                padding: 0;
                width: 25rem;

                color: v.$blanco;
                font-family: v.$cabin;
                font-size: 1.2rem;
                font-weight: v.$bold;

                &:hover {
                    cursor: pointer;
                    background-color: darken(v.$morado, 2);
                }
            }

            .campos {
                display: flex;
                flex-direction: column;
                gap: 1rem;

                .campo {
                    input {
                        border-radius: 1rem;
                        width: 25rem;
                        height: 4rem;
                        border: none;
                        background-color: #eee;
                        padding: 0;
                        margin: 0;
                        padding: 1.2rem;
                        font-size: 1.4rem;

                        &:focus {
                            outline: none;
                        }

                        &::placeholder {
                            font-family: v.$cabin;
                            width: 22.5rem;
                            height: 1.5rem;
                            font-size: 1.4rem;
                        }
                    }

                    .pass {
                        display: flex;
                        position: relative;

                        input {
                            flex: 1;
                        }

                        .mostrarPass {
                            background-color: transparent;
                            border: none;
                            color: v.$blanco;
                            cursor: pointer;
                            font-size: 1.4rem;
                            padding: .4rem;
                            width: fit-content;
                            position: absolute;
                            right: 10px;
                            top: 54%;
                            transform: translateY(-50%);
                            width: 2.4rem;
                            height: 2.4rem;
                        }
                    }
                }
            }
        }
    }

    .contenedorImagen {
        background-image: url('/build/img/fondoAuth.jpg');
        // max-width: 60rem;
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;

        h3 {
            font-size: 3.2rem;
            font-weight: v.$bold;
            color: v.$blanco;
            max-width: 38rem;
            height: 14rem;
            hyphens: none;
            margin: 0;
            text-align: left;
            margin-top: 11.8rem;
            margin-right: 4rem;
            text-transform: uppercase;

            &.cuentaConfirmada {
                direction: ltr;
            }
        }
    }
}

@media screen and (max-width: 970px) {
    .contenedorLogin {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh; // Asegúrate de que el contenedor tenga altura

        &.contenedorSingUp {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100vh; // Asegúrate de que el contenedor tenga altura
        }

        .contenedorImagen {
            display: none;
        }
    }
}

@media screen and (max-width: 1500px) {
    .contenedorLogin {
        .formularioLogin {
            .botones {

                .registro {
                    width: 9.8rem;
                }

                a {
                    height: auto;
                }
            }
        }
    }
}